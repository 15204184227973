/*
 * Core: General Layout Style
 * -------------------------
 */
html,
body {
  min-height: 100%;
}
.layout-boxed html,
.layout-boxed body {
  height: 100%;
}
body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Source Sans Pro', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-weight: 400;
  overflow-x: hidden;
  overflow-y: auto;
}
/* Layout */
.wrapper {
  min-height: 100%;
  position: relative;
  overflow: hidden;
}
.wrapper:before,
.wrapper:after {
  display: table;
  content: " ";
}
.wrapper:after {
  clear: both;
}
.layout-boxed .wrapper {
  max-width: 1250px;
  margin: 0 auto;
  min-height: 100%;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
  position: relative;
}
.layout-boxed {
  background: url('../../../node_modules/admin-lte/build/less/~admin-lte/dist/img/boxed-bg.jpg') repeat fixed;
}
/*
 * Content Wrapper - contains the main content
 * ```.right-side has been deprecated as of v2.0.0 in favor of .content-wrapper  ```
 */
.content-wrapper,
.right-side,
.main-footer {
  transition: transform 0.3s ease-in-out, margin 0.3s ease-in-out;
  margin-left: 230px;
  z-index: 820;
}
.layout-top-nav .content-wrapper,
.layout-top-nav .right-side,
.layout-top-nav .main-footer {
  margin-left: 0;
}
@media (max-width: 767px) {
  .content-wrapper,
  .right-side,
  .main-footer {
    margin-left: 0;
  }
}
@media (min-width: 768px) {
  .sidebar-collapse .content-wrapper,
  .sidebar-collapse .right-side,
  .sidebar-collapse .main-footer {
    margin-left: 0;
  }
}
@media (max-width: 767px) {
  .sidebar-open .content-wrapper,
  .sidebar-open .right-side,
  .sidebar-open .main-footer {
    transform: translate(230px, 0);
  }
}
.content-wrapper,
.right-side {
  min-height: 100%;
  background-color: #f4f4fa;
  z-index: 800;
}
.main-footer {
  background: #fff;
  padding: 15px;
  color: #444;
  border-top: 1px solid #EFF0F7;
}
/* Fixed layout */
.fixed .main-header,
.fixed .main-sidebar,
.fixed .left-side {
  position: fixed;
}
.fixed .main-header {
  top: 0;
  right: 0;
  left: 0;
}
.fixed .content-wrapper,
.fixed .right-side {
  padding-top: 50px;
}
@media (max-width: 767px) {
  .fixed .content-wrapper,
  .fixed .right-side {
    padding-top: 100px;
  }
}
.fixed.layout-boxed .wrapper {
  max-width: 100%;
}
body.hold-transition .content-wrapper,
body.hold-transition .right-side,
body.hold-transition .main-footer,
body.hold-transition .main-sidebar,
body.hold-transition .left-side,
body.hold-transition .main-header .navbar,
body.hold-transition .main-header .logo {
  /* Fix for IE */
  transition: none;
}
/* Content */
.content {
  min-height: 250px;
  padding: 15px;
  padding-right: 8px;
  padding-left: 8px;
  margin-right: auto;
  margin-left: auto;
}
/* H1 - H6 font */
h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-family: 'Source Sans Pro', sans-serif;
}
/* General Links */
a {
  color: #DF0079;
}
a:hover,
a:active,
a:focus {
  outline: none;
  text-decoration: none;
  color: #ac005d;
}
/* Page Header */
.page-header {
  margin: 10px 0 20px 0;
  font-size: 22px;
}
.page-header > small {
  color: #666;
  display: block;
  margin-top: 5px;
}
/*
 * Component: Main Header
 * ----------------------
 */
.main-header {
  position: relative;
  max-height: 100px;
  z-index: 1030;
}
.main-header .navbar {
  transition: margin-left 0.3s ease-in-out;
  margin-bottom: 0;
  margin-left: 230px;
  border: none;
  min-height: 50px;
  border-radius: 0;
}
.layout-top-nav .main-header .navbar {
  margin-left: 0;
}
.main-header #navbar-search-input.form-control {
  background: rgba(255, 255, 255, 0.2);
  border-color: transparent;
}
.main-header #navbar-search-input.form-control:focus,
.main-header #navbar-search-input.form-control:active {
  border-color: rgba(0, 0, 0, 0.1);
  background: rgba(255, 255, 255, 0.9);
}
.main-header #navbar-search-input.form-control::-moz-placeholder {
  color: #ccc;
  opacity: 1;
}
.main-header #navbar-search-input.form-control:-ms-input-placeholder {
  color: #ccc;
}
.main-header #navbar-search-input.form-control::-webkit-input-placeholder {
  color: #ccc;
}
.main-header .navbar-custom-menu,
.main-header .navbar-right {
  float: right;
}
@media (max-width: 991px) {
  .main-header .navbar-custom-menu a,
  .main-header .navbar-right a {
    color: inherit;
    background: transparent;
  }
}
@media (max-width: 767px) {
  .main-header .navbar-right {
    float: none;
  }
  .navbar-collapse .main-header .navbar-right {
    margin: 7.5px -15px;
  }
  .main-header .navbar-right > li {
    color: inherit;
    border: 0;
  }
}
.main-header .sidebar-toggle {
  float: left;
  background-color: transparent;
  background-image: none;
  padding: 15px 16px;
  font-family: fontAwesome;
}
.main-header .sidebar-toggle:before {
  content: "\f0c9";
}
.main-header .sidebar-toggle:hover {
  color: #fff;
}
.main-header .sidebar-toggle:focus,
.main-header .sidebar-toggle:active {
  background: transparent;
}
.main-header .sidebar-toggle .icon-bar {
  display: none;
}
.main-header .navbar .nav > li.user > a > .fa,
.main-header .navbar .nav > li.user > a > .glyphicon,
.main-header .navbar .nav > li.user > a > .ion {
  margin-right: 5px;
}
.main-header .navbar .nav > li > a > .label {
  position: absolute;
  top: 9px;
  right: 7px;
  text-align: center;
  font-size: 9px;
  padding: 2px 3px;
  line-height: 0.9;
}
.main-header .logo {
  transition: width 0.3s ease-in-out;
  display: block;
  float: left;
  height: 50px;
  font-size: 20px;
  line-height: 50px;
  text-align: center;
  width: 230px;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  padding: 0 15px;
  font-weight: 300;
  overflow: hidden;
}
.main-header .logo .logo-lg {
  display: block;
}
.main-header .logo .logo-mini {
  display: none;
}
.main-header .navbar-brand {
  color: #fff;
}
.content-header {
  position: relative;
  padding: 15px 15px 0 15px;
}
.content-header > h1 {
  margin: 0;
  font-size: 24px;
}
.content-header > h1 > small {
  font-size: 15px;
  display: inline-block;
  padding-left: 4px;
  font-weight: 300;
}
.content-header > .breadcrumb {
  float: right;
  background: transparent;
  margin-top: 0;
  margin-bottom: 0;
  font-size: 12px;
  padding: 7px 5px;
  position: absolute;
  top: 15px;
  right: 10px;
  border-radius: 2px;
}
.content-header > .breadcrumb > li > a {
  color: #444;
  text-decoration: none;
  display: inline-block;
}
.content-header > .breadcrumb > li > a > .fa,
.content-header > .breadcrumb > li > a > .glyphicon,
.content-header > .breadcrumb > li > a > .ion {
  margin-right: 5px;
}
.content-header > .breadcrumb > li + li:before {
  content: '>\00a0';
}
@media (max-width: 991px) {
  .content-header > .breadcrumb {
    position: relative;
    margin-top: 5px;
    top: 0;
    right: 0;
    float: none;
    background: #EFF0F7;
    padding-left: 10px;
  }
  .content-header > .breadcrumb li:before {
    color: #abb0d5;
  }
}
.navbar-toggle {
  color: #fff;
  border: 0;
  margin: 0;
  padding: 15px 16px;
}
@media (max-width: 991px) {
  .navbar-custom-menu .navbar-nav > li {
    float: left;
  }
  .navbar-custom-menu .navbar-nav {
    margin: 0;
    float: left;
  }
  .navbar-custom-menu .navbar-nav > li > a {
    padding-top: 15px;
    padding-bottom: 15px;
    line-height: 20px;
  }
}
@media (max-width: 767px) {
  .main-header {
    position: relative;
  }
  .main-header .logo,
  .main-header .navbar {
    width: 100%;
    float: none;
  }
  .main-header .navbar {
    margin: 0;
  }
  .main-header .navbar-custom-menu {
    float: right;
  }
}
@media (max-width: 991px) {
  .navbar-collapse.pull-left {
    float: none !important;
  }
  .navbar-collapse.pull-left + .navbar-custom-menu {
    display: block;
    position: absolute;
    top: 0;
    right: 40px;
  }
}
/*
 * Component: Sidebar
 * ------------------
 */
.main-sidebar,
.left-side {
  position: absolute;
  top: 0;
  left: 0;
  padding-top: 50px;
  min-height: 100%;
  width: 230px;
  z-index: 810;
  transition: transform 0.3s ease-in-out, width 0.3s ease-in-out;
}
@media (max-width: 767px) {
  .main-sidebar,
  .left-side {
    padding-top: 100px;
  }
}
@media (max-width: 767px) {
  .main-sidebar,
  .left-side {
    transform: translate(-230px, 0);
  }
}
@media (min-width: 768px) {
  .sidebar-collapse .main-sidebar,
  .sidebar-collapse .left-side {
    transform: translate(-230px, 0);
  }
}
@media (max-width: 767px) {
  .sidebar-open .main-sidebar,
  .sidebar-open .left-side {
    transform: translate(0, 0);
  }
}
.sidebar {
  padding-bottom: 10px;
}
.sidebar-form input:focus {
  border-color: transparent;
}
.user-panel {
  position: relative;
  width: 100%;
  padding: 10px;
  overflow: hidden;
}
.user-panel:before,
.user-panel:after {
  display: table;
  content: " ";
}
.user-panel:after {
  clear: both;
}
.user-panel > .image > img {
  width: 100%;
  max-width: 45px;
  height: auto;
}
.user-panel > .info {
  padding: 5px 5px 5px 15px;
  line-height: 1;
  position: absolute;
  left: 55px;
}
.user-panel > .info > p {
  font-weight: 600;
  margin-bottom: 9px;
}
.user-panel > .info > a {
  text-decoration: none;
  padding-right: 5px;
  margin-top: 3px;
  font-size: 11px;
}
.user-panel > .info > a > .fa,
.user-panel > .info > a > .ion,
.user-panel > .info > a > .glyphicon {
  margin-right: 3px;
}
.sidebar-menu {
  list-style: none;
  margin: 0;
  padding: 0;
}
.sidebar-menu > li {
  position: relative;
  margin: 0;
  padding: 0;
}
.sidebar-menu > li > a {
  padding: 12px 5px 12px 15px;
  display: block;
}
.sidebar-menu > li > a > .fa,
.sidebar-menu > li > a > .glyphicon,
.sidebar-menu > li > a > .ion {
  width: 20px;
}
.sidebar-menu > li .label,
.sidebar-menu > li .badge {
  margin-right: 5px;
}
.sidebar-menu > li .badge {
  margin-top: 3px;
}
.sidebar-menu li.header {
  padding: 10px 25px 10px 15px;
  font-size: 12px;
}
.sidebar-menu li > a > .fa-angle-left,
.sidebar-menu li > a > .pull-right-container > .fa-angle-left {
  width: auto;
  height: auto;
  padding: 0;
  margin-right: 10px;
}
.sidebar-menu li > a > .fa-angle-left {
  position: absolute;
  top: 50%;
  right: 10px;
  margin-top: -8px;
}
.sidebar-menu li.active > a > .fa-angle-left,
.sidebar-menu li.active > a > .pull-right-container > .fa-angle-left {
  transform: rotate(-90deg);
}
.sidebar-menu li.active > .treeview-menu {
  display: block;
}
.sidebar-menu .treeview-menu {
  display: none;
  list-style: none;
  padding: 0;
  margin: 0;
  padding-left: 5px;
}
.sidebar-menu .treeview-menu .treeview-menu {
  padding-left: 20px;
}
.sidebar-menu .treeview-menu > li {
  margin: 0;
}
.sidebar-menu .treeview-menu > li > a {
  padding: 5px 5px 5px 15px;
  display: block;
  font-size: 14px;
}
.sidebar-menu .treeview-menu > li > a > .fa,
.sidebar-menu .treeview-menu > li > a > .glyphicon,
.sidebar-menu .treeview-menu > li > a > .ion {
  width: 20px;
}
.sidebar-menu .treeview-menu > li > a > .pull-right-container > .fa-angle-left,
.sidebar-menu .treeview-menu > li > a > .pull-right-container > .fa-angle-down,
.sidebar-menu .treeview-menu > li > a > .fa-angle-left,
.sidebar-menu .treeview-menu > li > a > .fa-angle-down {
  width: auto;
}
.main-sidebar {
  padding-top: 0;
}
.main-sidebar .sidebar {
  transition: transform 0.3s linear, width 0.3s ease-in-out;
  width: 240px;
}
@media screen and (min-width: 768px) {
  .main-sidebar .logo-wrapper {
    transition: transform 0.3s linear, width 0.3s ease-in-out;
    width: 225px;
    height: 175px;
    margin-left: 16px;
    margin-bottom: 16px;
    padding: 8px;
    background-color: white;
    box-shadow: 0 8px 10px rgba(17, 17, 17, 0.03);
    border-radius: 0 0 8px 8px;
  }
  .main-sidebar .logo-wrapper .logo {
    height: 100%;
  }
  .main-sidebar .logo-wrapper .logo .logo-lg {
    transition: transform 0.3s linear, width 0.3s ease-in-out;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 1;
    height: 100%;
  }
  .main-sidebar .logo-wrapper .logo .logo-lg img {
    max-height: 100%;
    max-width: 100%;
  }
  .main-sidebar .logo-wrapper .logo .logo-mini {
    display: none;
  }
  .main-sidebar .logo-wrapper .logo__powered-by {
    display: flex;
    align-items: end;
    justify-content: end;
    gap: 4px;
    margin-bottom: 0;
    font-size: 11px;
  }
  .main-sidebar .logo-wrapper .logo__powered-by img {
    position: relative;
    top: -4px;
  }
}
.customer-support-sidebar {
  display: inline-block;
  width: 100%;
  margin-right: 15px;
  margin-bottom: 4px;
  padding: 8px 8px 8px 20px;
}
.customer-support-sidebar:hover {
  background-color: #e6e6f4;
}
.customer-support-sidebar:hover .customer-support-sidebar__icon {
  color: #363B4D;
}
.customer-support-sidebar .customer-support-sidebar__icon {
  font-size: 16px;
  color: #A7AEC9;
}
.customer-support-sidebar .customer-support-sidebar__text {
  padding-left: 6px;
  font-size: 14px;
  font-weight: 400;
}
.property-selector-sidebar {
  margin-left: 16px;
  margin-bottom: 16px;
  padding: 8px 12px;
  border-radius: 4px;
  background-color: #363B4D;
  color: #FCFCFC;
}
.property-selector-sidebar .property-selector-sidebar__dropdown-icon {
  display: none;
}
.property-selector-sidebar .property-selector-sidebar__dropdown-container {
  display: flex;
  gap: 8px;
  align-items: center;
}
.property-selector-sidebar .property-selector-sidebar__dropdown-container .selected-property {
  display: flex;
  flex-direction: column;
  width: 100%;
  font-size: 12px;
}
.property-selector-sidebar .property-selector-sidebar__dropdown-container .selected-property .selected-property__type {
  font-weight: 400;
  color: #e3e3e3;
}
.property-selector-sidebar .property-selector-sidebar__dropdown-container .selected-property .selected-property__name {
  font-weight: 600;
  color: #FCFCFC;
}
.property-selector-sidebar .dropdown-menu {
  width: 100%;
  margin: 0;
  padding: 12px 10px;
  background-color: #363B4D;
  border: none;
  border-top: 1px solid #161920;
  border-radius: 0 0 4px 4px;
  overflow-y: auto;
}
.property-selector-sidebar .dropdown-menu li {
  padding: 0;
  border-radius: 4px;
}
.property-selector-sidebar .dropdown-menu li a,
.property-selector-sidebar .dropdown-menu li a:hover {
  padding: 12px 10px;
  background-color: transparent;
  font-size: 12px;
  font-weight: 600;
  color: #FCFCFC;
  white-space: break-spaces;
}
.property-selector-sidebar .dropdown-menu li:hover {
  background-color: #21242f;
}
.property-selector-sidebar .dropdown-menu li.active {
  background-color: #21242f;
}
.property-selector-sidebar .dropdown-menu li.active a:hover {
  background-color: #21242f;
}
.property-selector-sidebar.open {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.property-selector-sidebar.open .property-selector-sidebar__dropdown-container i:before {
  content: '\f0d8';
}
.sidebar-menu li a {
  display: flex;
  align-items: start;
  padding: 8px 8px 8px 15px;
  font-size: 15px;
  gap: 12px;
}
.sidebar-menu li a i {
  margin-right: 0;
  padding: 0;
  text-align: center;
  color: #A7AEC9;
}
.sidebar-menu li a i:first-child {
  min-width: 24px;
  width: 24px;
  font-size: 16px;
}
.sidebar-menu li a span {
  display: flex;
  align-items: start;
  width: 100%;
}
.sidebar-menu li a span > span {
  flex-grow: 1;
  display: inline-block;
  white-space: normal;
}
.sidebar-menu li a span > i {
  display: flex;
  align-items: center;
  font-size: 16px;
  color: #363B4D;
}
.sidebar-menu li a span > i.fa-angle-down {
  position: static;
  margin: 0 4px 0 0;
}
.sidebar-menu li a span > i.label {
  margin-right: 0;
  padding: 4px 6px;
  font-style: normal;
  font-size: 12px;
}
.sidebar-menu li a span > i.label + i.label {
  margin-left: 4px;
}
.sidebar-menu li a:hover i {
  color: #363B4D;
}
.sidebar-menu li.active {
  background-color: #dfdff0;
}
.sidebar-menu li.active > a > i {
  color: #363B4D;
}
.sidebar-menu li.active > a > span > i {
  color: #363B4D;
}
.sidebar-menu li.active > a > span > i.fa-angle-down::before {
  content: '\f106';
}
.sidebar-menu:hover > a > span {
  padding-top: 8px;
  padding-bottom: 8px;
}
.sidebar-menu .treeview-menu {
  padding-left: 0;
}
.sidebar-menu .treeview-menu > li > a {
  display: flex;
  padding: 6px 8px 6px 25px;
  font-weight: 400;
}
.sidebar-menu .treeview-menu > li > a i {
  color: #A7AEC9;
}
.sidebar-menu .treeview-menu > li:hover {
  background-color: #dbdbef;
}
.sidebar-menu .treeview-menu > li:hover i {
  color: #363B4D;
}
.sidebar-menu .treeview-menu > li.active {
  background-color: #cdcde8;
}
.sidebar-menu .treeview-menu .treeview-menu {
  padding-left: 0;
  background-color: #d4d4ec;
}
.sidebar-menu .treeview-menu .treeview-menu > li > a {
  padding-left: 45px;
}
.sidebar-menu .treeview-menu .treeview-menu > li > a:before {
  content: '\f068';
  font-family: "Font Awesome 6 Pro";
  font-size: 12px;
  line-height: 20px;
}
.sidebar-menu .treeview-menu .treeview-menu > li:hover {
  background-color: #cacae7;
}
.sidebar-menu .treeview-menu .treeview-menu > li.active,
.sidebar-menu .treeview-menu .treeview-menu > li.active:hover {
  background-color: #c3c3e4;
}
.sidebar-menu > li .label,
.sidebar-menu > li .badge {
  margin-top: 0;
}
.sidebar-menu > li.divider {
  height: 1px;
  margin: 9px 0;
  overflow: hidden;
  background-color: #607d8b;
  opacity: 0.3;
  margin-left: 15px;
  margin-right: 5px;
}
.sidebar-menu .dashboard-divider {
  margin-top: 12px;
  margin-bottom: 12px;
}
.treeview-menu > li.divider {
  height: 1px;
  margin: 9px 0;
  overflow: hidden;
  background-color: #FCFCFC;
  opacity: 0.3;
  margin-right: 15px;
  margin-left: 10px;
}
@media screen and (min-width: 768px) {
  .sidebar-collapse .sidebar {
    width: 50px;
  }
  .sidebar-collapse .logo-wrapper {
    height: 50px;
    width: 50px;
    margin: 0 0 16px 0;
    background-color: transparent;
    border-radius: 0;
    box-shadow: none;
  }
  .sidebar-collapse .logo-wrapper .logo .logo-lg {
    position: absolute;
    opacity: 0;
    transition: none;
  }
  .sidebar-collapse .logo-wrapper .logo .logo-lg img {
    width: 0;
  }
  .sidebar-collapse .logo-wrapper .logo .logo-mini {
    display: block;
    text-align: center;
  }
  .sidebar-collapse .logo-wrapper .logo__powered-by {
    display: none !important;
  }
  .sidebar-collapse .customer-support-sidebar {
    margin-left: 12px;
    width: auto;
    padding: 4px;
  }
  .sidebar-collapse .customer-support-sidebar .customer-support-sidebar__icon {
    display: block;
    padding: 8px;
    font-size: 20px;
    text-align: center;
  }
  .sidebar-collapse .customer-support-sidebar .customer-support-sidebar__text {
    display: none;
  }
  .sidebar-collapse .customer-support-sidebar:hover {
    position: relative;
  }
  .sidebar-collapse .customer-support-sidebar:hover .customer-support-sidebar__icon {
    color: #363B4D;
  }
  .sidebar-collapse .customer-support-sidebar:hover .customer-support-sidebar__text {
    left: 35px;
    position: absolute;
    top: 0;
    display: block;
    width: 180px;
    padding: 8px 5px 8px 20px;
    background-color: #f4f4fa;
    border-radius: 0 4px 4px 0;
    font-size: 15px;
    font-weight: 600;
  }
  .sidebar-collapse .property-selector-sidebar {
    margin-left: 11px;
    margin-right: 4px;
    padding: 0;
  }
  .sidebar-collapse .property-selector-sidebar .property-selector-sidebar__dropdown-icon {
    display: block;
    padding-top: 4px;
    padding-bottom: 4px;
    text-align: center;
  }
  .sidebar-collapse .property-selector-sidebar .property-selector-sidebar__dropdown-icon .property-selector-sidebar__icon {
    font-size: 20px;
  }
  .sidebar-collapse .property-selector-sidebar .property-selector-sidebar__dropdown-icon .property-selector-sidebar__icon-description {
    display: none;
  }
  .sidebar-collapse .property-selector-sidebar .property-selector-sidebar__dropdown-icon:hover .property-selector-sidebar__icon-description {
    position: absolute;
    display: block;
    width: 180px;
    padding: 8px 5px 8px 20px;
    background-color: #f4f4fa;
    border-radius: 0 4px 4px 0;
    font-size: 15px;
    font-weight: 600;
    top: 0;
    left: 35px;
    text-align: start;
    color: #363B4D;
  }
  .sidebar-collapse .property-selector-sidebar .property-selector-sidebar__dropdown-container {
    display: none;
  }
  .sidebar-collapse.sidebar-mini .sidebar-menu > li:hover > a > span.menu-link-text:not(.pull-right) {
    display: flex !important;
  }
  .sidebar-collapse .sidebar-menu > li > a {
    padding-left: 12px;
  }
  .sidebar-collapse .sidebar-menu > li > a > span {
    padding: 8px 8px 8px 12px;
  }
  .sidebar-collapse .sidebar-menu > li > a span > i:not(.label) {
    display: none;
  }
  .sidebar-collapse .sidebar-menu > li:hover > a:first-child i {
    color: #363B4D;
    font-weight: 600;
  }
  .sidebar-collapse .sidebar-menu > li:hover > ul.treeview-menu {
    top: 36px;
  }
  .sidebar-collapse .sidebar-menu > li:hover > ul.treeview-menu li a {
    padding-left: 16px;
  }
  .sidebar-collapse .sidebar-menu > li:hover > ul.treeview-menu li .treeview-menu li a {
    padding-left: 16px;
  }
}
.sidebar-collapse-item-description {
  position: absolute;
  top: 0;
  display: block;
  width: 180px;
  padding: 8px 5px 8px 20px;
  background-color: #f4f4fa;
  border-radius: 0 4px 4px 0;
  font-size: 15px;
  font-weight: 600;
}
.transition-sidebar {
  transition: transform 0.3s linear, width 0.3s ease-in-out;
}
@media screen and (min-width: 768px) {
  .dark-theme body:not(.sidebar-collapse) .main-sidebar .logo-wrapper {
    margin: 0 8px 16px 8px;
  }
}
.dark-theme .customer-support-sidebar:hover {
  padding-left: 17px;
  border-left: 3px solid #DF0079;
  background-color: #2e3241;
}
.dark-theme .customer-support-sidebar .customer-support-sidebar__icon {
  color: #FCFCFC;
}
.dark-theme .property-selector-sidebar {
  margin: 0 8px 16px 8px;
  background-color: #FCFCFC;
  color: #363B4D;
}
.dark-theme .property-selector-sidebar .property-selector-sidebar__dropdown-container .selected-property .selected-property__type {
  color: #363B4D;
}
.dark-theme .property-selector-sidebar .property-selector-sidebar__dropdown-container .selected-property .selected-property__name {
  color: #363B4D;
}
.dark-theme .property-selector-sidebar .dropdown-menu {
  background-color: #FCFCFC;
  border-top-color: #e9e9f5;
}
.dark-theme .property-selector-sidebar .dropdown-menu li a,
.dark-theme .property-selector-sidebar .dropdown-menu li a:hover {
  color: #363B4D;
}
.dark-theme .property-selector-sidebar .dropdown-menu li:hover {
  background-color: #e9e9f5;
}
.dark-theme .property-selector-sidebar .dropdown-menu li.active {
  color: #363B4D;
  background-color: #e9e9f5;
}
.dark-theme .property-selector-sidebar .dropdown-menu li.active a:hover {
  color: #363B4D;
  background-color: #e9e9f5;
}
.dark-theme .sidebar-menu li a i {
  color: #f4f4fa;
}
.dark-theme .sidebar-menu li a span > i {
  color: #ededf7;
}
.dark-theme .sidebar-menu li a:hover i {
  color: #ededf7;
}
.dark-theme .sidebar-menu li.active {
  background-color: #303444;
}
.dark-theme .sidebar-menu li.active > a > i {
  color: #ededf7;
}
.dark-theme .sidebar-menu li.active > a > span > i {
  color: #ededf7;
}
.dark-theme .sidebar-menu .treeview-menu > li:hover {
  background-color: #3e4459;
}
.dark-theme .sidebar-menu .treeview-menu > li:hover > a {
  border-left: 3px solid #DF0079;
  padding-left: 22px;
}
.dark-theme .sidebar-menu .treeview-menu > li.active {
  background-color: #3e4459;
}
.dark-theme .sidebar-menu .treeview-menu > li.active > a {
  border-left: 3px solid #DF0079;
  padding-left: 22px;
}
.dark-theme .sidebar-menu .treeview-menu .treeview-menu {
  background-color: #58607d;
}
.dark-theme .sidebar-menu .treeview-menu .treeview-menu > li:hover {
  background-color: #4f5771;
}
.dark-theme .sidebar-menu .treeview-menu .treeview-menu > li:hover > a {
  border-left: 3px solid #DF0079;
  padding-left: 42px;
}
.dark-theme .sidebar-menu .treeview-menu .treeview-menu > li.active,
.dark-theme .sidebar-menu .treeview-menu .treeview-menu > li.active:hover {
  background-color: #4f5771;
}
.dark-theme .sidebar-menu .treeview-menu .treeview-menu > li.active > a,
.dark-theme .sidebar-menu .treeview-menu .treeview-menu > li.active:hover > a {
  border-left: 3px solid #DF0079;
  padding-left: 42px;
}
.dark-theme .sidebar-menu > li.divider {
  height: 1px;
  margin: 9px 0;
  overflow: hidden;
  background-color: #f4f4fa;
}
.dark-theme .treeview-menu > li.divider {
  height: 1px;
  margin: 9px 0;
  overflow: hidden;
  background-color: #f4f4fa;
}
@media screen and (min-width: 768px) {
  .dark-theme .sidebar-collapse .customer-support-sidebar {
    margin-left: 0;
    margin-right: 0;
  }
  .dark-theme .sidebar-collapse .customer-support-sidebar:hover {
    padding: 4px 0 4px 1px;
  }
  .dark-theme .sidebar-collapse .customer-support-sidebar:hover > i {
    color: #FCFCFC;
  }
  .dark-theme .sidebar-collapse .customer-support-sidebar:hover > .customer-support-sidebar__text {
    left: 47px;
    background: #2e3241;
  }
  .dark-theme .sidebar-collapse .property-selector-sidebar .property-selector-sidebar__dropdown-icon:hover .property-selector-sidebar__icon-description {
    background-color: #363B4D;
    color: #FCFCFC;
  }
  .dark-theme .sidebar-collapse .sidebar-menu > li:hover > a:first-child i {
    color: #FCFCFC;
  }
  .dark-theme .sidebar-collapse .sidebar-menu > li:hover > ul.treeview-menu > li:hover > a,
  .dark-theme .sidebar-collapse .sidebar-menu > li:hover > ul.treeview-menu > li.active > a {
    padding-left: 12px;
  }
  .dark-theme .sidebar-collapse .sidebar-menu > li:hover > ul.treeview-menu > li:hover .treeview-menu li a,
  .dark-theme .sidebar-collapse .sidebar-menu > li:hover > ul.treeview-menu > li.active .treeview-menu li a {
    border-left: none;
    padding-left: 16px;
  }
  .dark-theme .sidebar-collapse .sidebar-menu > li > a {
    padding-left: 9px;
  }
}
/*
 * Component: Sidebar Mini
 */
@media (min-width: 768px) {
  .sidebar-mini.sidebar-collapse .content-wrapper,
  .sidebar-mini.sidebar-collapse .right-side,
  .sidebar-mini.sidebar-collapse .main-footer {
    margin-left: 50px !important;
    z-index: 840;
  }
  .sidebar-mini.sidebar-collapse .main-sidebar {
    transform: translate(0, 0);
    width: 50px !important;
    z-index: 850;
  }
  .sidebar-mini.sidebar-collapse .sidebar-menu > li {
    position: relative;
  }
  .sidebar-mini.sidebar-collapse .sidebar-menu > li > a {
    margin-right: 0;
  }
  .sidebar-mini.sidebar-collapse .sidebar-menu > li > a > span {
    border-top-right-radius: 4px;
  }
  .sidebar-mini.sidebar-collapse .sidebar-menu > li:not(.treeview) > a > span {
    border-bottom-right-radius: 4px;
  }
  .sidebar-mini.sidebar-collapse .sidebar-menu > li > .treeview-menu {
    padding-top: 5px;
    padding-bottom: 5px;
    border-bottom-right-radius: 4px;
  }
  .sidebar-mini.sidebar-collapse .sidebar-menu > li:hover > a > span:not(.pull-right),
  .sidebar-mini.sidebar-collapse .sidebar-menu > li:hover > .treeview-menu {
    display: block !important;
    position: absolute;
    width: 180px;
    left: 50px;
  }
  .sidebar-mini.sidebar-collapse .sidebar-menu > li:hover > a > span {
    top: 0;
    margin-left: -3px;
    padding: 12px 5px 12px 20px;
    background-color: inherit;
  }
  .sidebar-mini.sidebar-collapse .sidebar-menu > li:hover > a > .pull-right-container {
    float: right;
    width: auto!important;
    left: 200px!important;
    top: 10px!important;
  }
  .sidebar-mini.sidebar-collapse .sidebar-menu > li:hover > a > .pull-right-container > .label:not(:first-of-type) {
    display: none;
  }
  .sidebar-mini.sidebar-collapse .sidebar-menu > li:hover > .treeview-menu {
    top: 44px;
    margin-left: 0;
  }
  .sidebar-mini.sidebar-collapse .main-sidebar .user-panel > .info,
  .sidebar-mini.sidebar-collapse .sidebar-form,
  .sidebar-mini.sidebar-collapse .sidebar-menu > li > a > span,
  .sidebar-mini.sidebar-collapse .sidebar-menu > li > .treeview-menu,
  .sidebar-mini.sidebar-collapse .sidebar-menu > li > a > .pull-right,
  .sidebar-mini.sidebar-collapse .sidebar-menu li.header {
    display: none !important;
    -webkit-transform: translateZ(0);
  }
  .sidebar-mini.sidebar-collapse .main-header .logo {
    width: 50px;
  }
  .sidebar-mini.sidebar-collapse .main-header .logo > .logo-mini {
    display: block;
    margin-left: -15px;
    margin-right: -15px;
    font-size: 18px;
  }
  .sidebar-mini.sidebar-collapse .main-header .logo > .logo-lg {
    display: none;
  }
  .sidebar-mini.sidebar-collapse .main-header .navbar {
    margin-left: 50px;
  }
}
.sidebar-menu,
.main-sidebar .user-panel,
.sidebar-menu > li.header {
  white-space: nowrap;
  overflow: hidden;
}
.sidebar-menu:hover {
  overflow: visible;
}
.sidebar-form,
.sidebar-menu > li.header {
  overflow: hidden;
  text-overflow: clip;
}
.sidebar-menu li > a {
  position: relative;
}
.sidebar-menu li > a > .pull-right-container {
  position: absolute;
  right: 10px;
  top: 50%;
  margin-top: -7px;
}
/*
 * Component: Control sidebar. By default, this is the right sidebar.
 */
.control-sidebar-bg {
  position: fixed;
  z-index: 1000;
  bottom: 0;
}
.control-sidebar-bg,
.control-sidebar {
  top: 0;
  right: -230px;
  width: 230px;
  transition: right 0.3s ease-in-out;
}
.control-sidebar {
  position: absolute;
  padding-top: 50px;
  z-index: 1010;
}
@media (max-width: 768px) {
  .control-sidebar {
    padding-top: 100px;
  }
}
.control-sidebar > .tab-content {
  padding: 10px 15px;
}
.control-sidebar.control-sidebar-open,
.control-sidebar.control-sidebar-open + .control-sidebar-bg {
  right: 0;
}
.control-sidebar-open .control-sidebar-bg,
.control-sidebar-open .control-sidebar {
  right: 0;
}
@media (min-width: 768px) {
  .control-sidebar-open .content-wrapper,
  .control-sidebar-open .right-side,
  .control-sidebar-open .main-footer {
    margin-right: 230px;
  }
}
.nav-tabs.control-sidebar-tabs > li:first-of-type > a,
.nav-tabs.control-sidebar-tabs > li:first-of-type > a:hover,
.nav-tabs.control-sidebar-tabs > li:first-of-type > a:focus {
  border-left-width: 0;
}
.nav-tabs.control-sidebar-tabs > li > a {
  border-radius: 0;
}
.nav-tabs.control-sidebar-tabs > li > a,
.nav-tabs.control-sidebar-tabs > li > a:hover {
  border-top: none;
  border-right: none;
  border-left: 1px solid transparent;
  border-bottom: 1px solid transparent;
}
.nav-tabs.control-sidebar-tabs > li > a .icon {
  font-size: 16px;
}
.nav-tabs.control-sidebar-tabs > li.active > a,
.nav-tabs.control-sidebar-tabs > li.active > a:hover,
.nav-tabs.control-sidebar-tabs > li.active > a:focus,
.nav-tabs.control-sidebar-tabs > li.active > a:active {
  border-top: none;
  border-right: none;
  border-bottom: none;
}
@media (max-width: 768px) {
  .nav-tabs.control-sidebar-tabs {
    display: table;
  }
  .nav-tabs.control-sidebar-tabs > li {
    display: table-cell;
  }
}
.control-sidebar-heading {
  font-weight: 400;
  font-size: 16px;
  padding: 10px 0;
  margin-bottom: 10px;
}
.control-sidebar-subheading {
  display: block;
  font-weight: 400;
  font-size: 14px;
}
.control-sidebar-menu {
  list-style: none;
  padding: 0;
  margin: 0 -15px;
}
.control-sidebar-menu > li > a {
  display: block;
  padding: 10px 15px;
}
.control-sidebar-menu > li > a:before,
.control-sidebar-menu > li > a:after {
  display: table;
  content: " ";
}
.control-sidebar-menu > li > a:after {
  clear: both;
}
.control-sidebar-menu > li > a > .control-sidebar-subheading {
  margin-top: 0;
}
.control-sidebar-menu .menu-icon {
  float: left;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  text-align: center;
  line-height: 35px;
}
.control-sidebar-menu .menu-info {
  margin-left: 45px;
  margin-top: 3px;
}
.control-sidebar-menu .menu-info > .control-sidebar-subheading {
  margin: 0;
}
.control-sidebar-menu .menu-info > p {
  margin: 0;
  font-size: 11px;
}
.control-sidebar-menu .progress {
  margin: 0;
}
.control-sidebar-dark {
  color: #FCFCFC;
}
.control-sidebar-dark,
.control-sidebar-dark + .control-sidebar-bg {
  background: #363B4D;
}
.control-sidebar-dark .nav-tabs.control-sidebar-tabs {
  border-bottom: #303444;
}
.control-sidebar-dark .nav-tabs.control-sidebar-tabs > li > a {
  background: #2b303e;
  color: #FCFCFC;
}
.control-sidebar-dark .nav-tabs.control-sidebar-tabs > li > a,
.control-sidebar-dark .nav-tabs.control-sidebar-tabs > li > a:hover,
.control-sidebar-dark .nav-tabs.control-sidebar-tabs > li > a:focus {
  border-left-color: #272b38;
  border-bottom-color: #272b38;
}
.control-sidebar-dark .nav-tabs.control-sidebar-tabs > li > a:hover,
.control-sidebar-dark .nav-tabs.control-sidebar-tabs > li > a:focus,
.control-sidebar-dark .nav-tabs.control-sidebar-tabs > li > a:active {
  background: #303444;
}
.control-sidebar-dark .nav-tabs.control-sidebar-tabs > li > a:hover {
  color: #fff;
}
.control-sidebar-dark .nav-tabs.control-sidebar-tabs > li.active > a,
.control-sidebar-dark .nav-tabs.control-sidebar-tabs > li.active > a:hover,
.control-sidebar-dark .nav-tabs.control-sidebar-tabs > li.active > a:focus,
.control-sidebar-dark .nav-tabs.control-sidebar-tabs > li.active > a:active {
  background: #363B4D;
  color: #fff;
}
.control-sidebar-dark .control-sidebar-heading,
.control-sidebar-dark .control-sidebar-subheading {
  color: #fff;
}
.control-sidebar-dark .control-sidebar-menu > li > a:hover {
  background: #2e3241;
}
.control-sidebar-dark .control-sidebar-menu > li > a .menu-info > p {
  color: #FCFCFC;
}
.control-sidebar-light {
  color: #4b526b;
}
.control-sidebar-light,
.control-sidebar-light + .control-sidebar-bg {
  background: #f4f4fa;
  border-left: 1px solid #EFF0F7;
}
.control-sidebar-light .nav-tabs.control-sidebar-tabs {
  border-bottom: #EFF0F7;
}
.control-sidebar-light .nav-tabs.control-sidebar-tabs > li > a {
  background: #e2e2f2;
  color: #363B4D;
}
.control-sidebar-light .nav-tabs.control-sidebar-tabs > li > a,
.control-sidebar-light .nav-tabs.control-sidebar-tabs > li > a:hover,
.control-sidebar-light .nav-tabs.control-sidebar-tabs > li > a:focus {
  border-left-color: #EFF0F7;
  border-bottom-color: #EFF0F7;
}
.control-sidebar-light .nav-tabs.control-sidebar-tabs > li > a:hover,
.control-sidebar-light .nav-tabs.control-sidebar-tabs > li > a:focus,
.control-sidebar-light .nav-tabs.control-sidebar-tabs > li > a:active {
  background: #e9e9f5;
}
.control-sidebar-light .nav-tabs.control-sidebar-tabs > li.active > a,
.control-sidebar-light .nav-tabs.control-sidebar-tabs > li.active > a:hover,
.control-sidebar-light .nav-tabs.control-sidebar-tabs > li.active > a:focus,
.control-sidebar-light .nav-tabs.control-sidebar-tabs > li.active > a:active {
  background: #f4f4fa;
  color: #111;
}
.control-sidebar-light .control-sidebar-heading,
.control-sidebar-light .control-sidebar-subheading {
  color: #111;
}
.control-sidebar-light .control-sidebar-menu {
  margin-left: -14px;
}
.control-sidebar-light .control-sidebar-menu > li > a:hover {
  background: #e9e9f5;
}
.control-sidebar-light .control-sidebar-menu > li > a .menu-info > p {
  color: #4b526b;
}
/*
 * Component: Form
 * ---------------
 */
.form-control {
  border-radius: 6px;
  box-shadow: none;
  border-color: #EFF0F7;
}
.form-control:focus {
  border-color: #363B4D;
  box-shadow: none;
}
.form-control::-moz-placeholder,
.form-control:-ms-input-placeholder,
.form-control::-webkit-input-placeholder {
  color: #bbb;
  opacity: 1;
}
.form-control:not(select) {
  appearance: none;
}
.form-group.has-success label {
  color: #2D7906;
}
.form-group.has-success .form-control,
.form-group.has-success .input-group-addon {
  border-color: #2D7906;
  box-shadow: none;
}
.form-group.has-success .help-block {
  color: #2D7906;
}
.form-group.has-warning label {
  color: #F4B740;
}
.form-group.has-warning .form-control,
.form-group.has-warning .input-group-addon {
  border-color: #F4B740;
  box-shadow: none;
}
.form-group.has-warning .help-block {
  color: #F4B740;
}
.form-group.has-error label {
  color: #FF0000;
}
.form-group.has-error .form-control,
.form-group.has-error .input-group-addon {
  border-color: #FF0000;
  box-shadow: none;
}
.form-group.has-error .help-block {
  color: #FF0000;
}
/* Input group */
.input-group .input-group-addon {
  border-radius: 6px;
  border-color: #EFF0F7;
  background-color: #fff;
}
/* button groups */
.btn-group-vertical .btn.btn-flat:first-of-type,
.btn-group-vertical .btn.btn-flat:last-of-type {
  border-radius: 0;
}
.icheck > label {
  padding-left: 0;
}
/* support Font Awesome icons in form-control */
.form-control-feedback.fa {
  line-height: 34px;
}
.input-lg + .form-control-feedback.fa,
.input-group-lg + .form-control-feedback.fa,
.form-group-lg .form-control + .form-control-feedback.fa {
  line-height: 41px;
}
.input-sm + .form-control-feedback.fa,
.input-group-sm + .form-control-feedback.fa,
.form-group-sm .form-control + .form-control-feedback.fa {
  line-height: 28px;
}
/*
 * Component: Progress Bar
 * -----------------------
 */
.progress,
.progress > .progress-bar {
  box-shadow: none;
}
.progress,
.progress > .progress-bar,
.progress .progress-bar,
.progress > .progress-bar .progress-bar {
  border-radius: 1px;
}
/* size variation */
.progress.sm,
.progress-sm {
  height: 10px;
}
.progress.sm,
.progress-sm,
.progress.sm .progress-bar,
.progress-sm .progress-bar {
  border-radius: 1px;
}
.progress.xs,
.progress-xs {
  height: 7px;
}
.progress.xs,
.progress-xs,
.progress.xs .progress-bar,
.progress-xs .progress-bar {
  border-radius: 4px;
}
.progress.xxs,
.progress-xxs {
  height: 3px;
}
.progress.xxs,
.progress-xxs,
.progress.xxs .progress-bar,
.progress-xxs .progress-bar {
  border-radius: 4px;
}
/* Vertical bars */
.progress.vertical {
  position: relative;
  width: 30px;
  height: 200px;
  display: inline-block;
  margin-right: 10px;
}
.progress.vertical > .progress-bar {
  width: 100%;
  position: absolute;
  bottom: 0;
}
.progress.vertical.sm,
.progress.vertical.progress-sm {
  width: 20px;
}
.progress.vertical.xs,
.progress.vertical.progress-xs {
  width: 10px;
}
.progress.vertical.xxs,
.progress.vertical.progress-xxs {
  width: 3px;
}
.progress-group .progress-text {
  font-weight: 600;
}
.progress-group .progress-number {
  float: right;
}
/* Remove margins from progress bars when put in a table */
.table tr > td .progress {
  margin: 0;
}
.progress-bar-light-blue,
.progress-bar-primary {
  background-color: #363B4D;
}
.progress-striped .progress-bar-light-blue,
.progress-striped .progress-bar-primary {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
}
.progress-bar-green,
.progress-bar-success {
  background-color: #2D7906;
}
.progress-striped .progress-bar-green,
.progress-striped .progress-bar-success {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
}
.progress-bar-aqua,
.progress-bar-info {
  background-color: #00c0ef;
}
.progress-striped .progress-bar-aqua,
.progress-striped .progress-bar-info {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
}
.progress-bar-yellow,
.progress-bar-warning {
  background-color: #F4B740;
}
.progress-striped .progress-bar-yellow,
.progress-striped .progress-bar-warning {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
}
.progress-bar-red,
.progress-bar-danger {
  background-color: #FF0000;
}
.progress-striped .progress-bar-red,
.progress-striped .progress-bar-danger {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
}
/*
 * Component: Small Box
 * --------------------
 */
.small-box {
  border-radius: 2px;
  position: relative;
  display: block;
  margin-bottom: 20px;
  box-shadow: 0 8px 10px rgba(17, 17, 17, 0.03);
}
.small-box > .inner {
  padding: 10px;
}
.small-box > .small-box-footer {
  position: relative;
  text-align: center;
  padding: 3px 0;
  color: #fff;
  color: rgba(255, 255, 255, 0.8);
  display: block;
  z-index: 10;
  background: rgba(0, 0, 0, 0.1);
  text-decoration: none;
}
.small-box > .small-box-footer:hover {
  color: #fff;
  background: rgba(0, 0, 0, 0.15);
}
.small-box h3 {
  font-size: 38px;
  font-weight: bold;
  margin: 0 0 10px 0;
  white-space: nowrap;
  padding: 0;
}
.small-box p {
  font-size: 15px;
}
.small-box p > small {
  display: block;
  color: #f9f9f9;
  font-size: 13px;
  margin-top: 5px;
}
.small-box h3,
.small-box p {
  z-index: 5;
}
.small-box .icon {
  transition: all 0.3s linear;
  position: absolute;
  top: -10px;
  right: 10px;
  z-index: 0;
  font-size: 90px;
  color: rgba(0, 0, 0, 0.15);
}
.small-box:hover {
  text-decoration: none;
  color: #f9f9f9;
}
.small-box:hover .icon {
  font-size: 95px;
}
@media (max-width: 767px) {
  .small-box {
    text-align: center;
  }
  .small-box .icon {
    display: none;
  }
  .small-box p {
    font-size: 12px;
  }
}
/*
 * Component: Box
 * --------------
 */
.box {
  position: relative;
  border-radius: 8px;
  background: #ffffff;
  border-top: 3px solid #607d8b;
  margin-bottom: 20px;
  width: 100%;
  box-shadow: 0 8px 10px rgba(17, 17, 17, 0.03);
}
.box.box-primary {
  border-top-color: #363B4D;
}
.box.box-info {
  border-top-color: #00c0ef;
}
.box.box-danger {
  border-top-color: #FF0000;
}
.box.box-warning {
  border-top-color: #F4B740;
}
.box.box-success {
  border-top-color: #2D7906;
}
.box.box-default {
  border-top-color: #EFF0F7;
}
.box.collapsed-box .box-body,
.box.collapsed-box .box-footer {
  display: none;
}
.box .nav-stacked > li {
  border-bottom: 1px solid #D9DBE9;
  margin: 0;
}
.box .nav-stacked > li:last-of-type {
  border-bottom: none;
}
.box.height-control .box-body {
  max-height: 300px;
  overflow: auto;
}
.box .border-right {
  border-right: 1px solid #D9DBE9;
}
.box .border-left {
  border-left: 1px solid #D9DBE9;
}
.box.box-solid {
  border-top: 0;
}
.box.box-solid > .box-header .btn.btn-default {
  background: transparent;
}
.box.box-solid > .box-header .btn:hover,
.box.box-solid > .box-header a:hover {
  background: rgba(0, 0, 0, 0.1);
}
.box.box-solid.box-default {
  border: 1px solid #EFF0F7;
}
.box.box-solid.box-default > .box-header {
  color: #444;
  background: #EFF0F7;
  background-color: #EFF0F7;
}
.box.box-solid.box-default > .box-header a,
.box.box-solid.box-default > .box-header .btn {
  color: #444;
}
.box.box-solid.box-primary {
  border: 1px solid #363B4D;
}
.box.box-solid.box-primary > .box-header {
  color: #fff;
  background: #363B4D;
  background-color: #363B4D;
}
.box.box-solid.box-primary > .box-header a,
.box.box-solid.box-primary > .box-header .btn {
  color: #fff;
}
.box.box-solid.box-info {
  border: 1px solid #00c0ef;
}
.box.box-solid.box-info > .box-header {
  color: #fff;
  background: #00c0ef;
  background-color: #00c0ef;
}
.box.box-solid.box-info > .box-header a,
.box.box-solid.box-info > .box-header .btn {
  color: #fff;
}
.box.box-solid.box-danger {
  border: 1px solid #FF0000;
}
.box.box-solid.box-danger > .box-header {
  color: #fff;
  background: #FF0000;
  background-color: #FF0000;
}
.box.box-solid.box-danger > .box-header a,
.box.box-solid.box-danger > .box-header .btn {
  color: #fff;
}
.box.box-solid.box-warning {
  border: 1px solid #F4B740;
}
.box.box-solid.box-warning > .box-header {
  color: #fff;
  background: #F4B740;
  background-color: #F4B740;
}
.box.box-solid.box-warning > .box-header a,
.box.box-solid.box-warning > .box-header .btn {
  color: #fff;
}
.box.box-solid.box-success {
  border: 1px solid #2D7906;
}
.box.box-solid.box-success > .box-header {
  color: #fff;
  background: #2D7906;
  background-color: #2D7906;
}
.box.box-solid.box-success > .box-header a,
.box.box-solid.box-success > .box-header .btn {
  color: #fff;
}
.box.box-solid > .box-header > .box-tools .btn {
  border: 0;
  box-shadow: none;
}
.box.box-solid[class*='bg'] > .box-header {
  color: #fff;
}
.box .box-group > .box {
  margin-bottom: 5px;
}
.box .knob-label {
  text-align: center;
  color: #333;
  font-weight: 100;
  font-size: 12px;
  margin-bottom: 0.3em;
}
.box > .overlay,
.overlay-wrapper > .overlay,
.box > .loading-img,
.overlay-wrapper > .loading-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.box .overlay,
.overlay-wrapper .overlay {
  z-index: 50;
  background: rgba(255, 255, 255, 0.7);
  border-radius: 8px;
}
.box .overlay > .fa,
.overlay-wrapper .overlay > .fa {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -15px;
  margin-top: -15px;
  color: #000;
  font-size: 30px;
}
.box .overlay.dark,
.overlay-wrapper .overlay.dark {
  background: rgba(0, 0, 0, 0.5);
}
.box-header:before,
.box-body:before,
.box-footer:before,
.box-header:after,
.box-body:after,
.box-footer:after {
  display: table;
  content: " ";
}
.box-header:after,
.box-body:after,
.box-footer:after {
  clear: both;
}
.box-header {
  color: #444;
  display: block;
  padding: 10px;
  position: relative;
}
.box-header.with-border {
  border-bottom: 1px solid #D9DBE9;
}
.collapsed-box .box-header.with-border {
  border-bottom: none;
}
.box-header > .fa,
.box-header > .glyphicon,
.box-header > .ion,
.box-header .box-title {
  display: inline-block;
  font-size: 18px;
  margin: 0;
  line-height: 1;
}
.box-header > .fa,
.box-header > .glyphicon,
.box-header > .ion {
  margin-right: 5px;
}
.box-header > .box-tools {
  position: absolute;
  right: 10px;
  top: 5px;
}
.box-header > .box-tools [data-toggle="tooltip"] {
  position: relative;
}
.box-header > .box-tools.pull-right .dropdown-menu {
  right: 0;
  left: auto;
}
.btn-box-tool {
  padding: 5px;
  font-size: 12px;
  background: transparent;
  color: #36474f;
}
.open .btn-box-tool,
.btn-box-tool:hover {
  color: #0d1012;
}
.btn-box-tool.btn:active {
  box-shadow: none;
}
.box-body {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
  padding: 10px;
}
.no-header .box-body {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}
.box-body > .table {
  margin-bottom: 0;
}
.box-body .fc {
  margin-top: 5px;
}
.box-body .full-width-chart {
  margin: -19px;
}
.box-body.no-padding .full-width-chart {
  margin: -9px;
}
.box-body .box-pane {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 8px;
}
.box-body .box-pane-right {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 0;
}
.box-footer {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
  border-top: 1px solid #D9DBE9;
  padding: 10px;
  background-color: #fff;
}
.chart-legend {
  margin: 10px 0;
}
@media (max-width: 991px) {
  .chart-legend > li {
    float: left;
    margin-right: 10px;
  }
}
.box-comments {
  background: #f7f7f7;
}
.box-comments .box-comment {
  padding: 8px 0;
  border-bottom: 1px solid #eee;
}
.box-comments .box-comment:before,
.box-comments .box-comment:after {
  display: table;
  content: " ";
}
.box-comments .box-comment:after {
  clear: both;
}
.box-comments .box-comment:last-of-type {
  border-bottom: 0;
}
.box-comments .box-comment:first-of-type {
  padding-top: 0;
}
.box-comments .box-comment img {
  float: left;
}
.box-comments .comment-text {
  margin-left: 40px;
  color: #555;
}
.box-comments .username {
  color: #444;
  display: block;
  font-weight: 600;
}
.box-comments .text-muted {
  font-weight: 400;
  font-size: 12px;
}
/* Widget: TODO LIST */
.todo-list {
  margin: 0;
  padding: 0;
  list-style: none;
  overflow: auto;
}
.todo-list > li {
  border-radius: 2px;
  padding: 10px;
  background: #f4f4f4;
  margin-bottom: 2px;
  border-left: 2px solid #e6e7e8;
  color: #444;
}
.todo-list > li:last-of-type {
  margin-bottom: 0;
}
.todo-list > li > input[type='checkbox'] {
  margin: 0 10px 0 5px;
}
.todo-list > li .text {
  display: inline-block;
  margin-left: 5px;
  font-weight: 600;
}
.todo-list > li .label {
  margin-left: 10px;
  font-size: 9px;
}
.todo-list > li .tools {
  display: none;
  float: right;
  color: #FF0000;
}
.todo-list > li .tools > .fa,
.todo-list > li .tools > .glyphicon,
.todo-list > li .tools > .ion {
  margin-right: 5px;
  cursor: pointer;
}
.todo-list > li:hover .tools {
  display: inline-block;
}
.todo-list > li.done {
  color: #999;
}
.todo-list > li.done .text {
  text-decoration: line-through;
  font-weight: 500;
}
.todo-list > li.done .label {
  background: #EFF0F7 !important;
}
.todo-list .danger {
  border-left-color: #FF0000;
}
.todo-list .warning {
  border-left-color: #F4B740;
}
.todo-list .info {
  border-left-color: #00c0ef;
}
.todo-list .success {
  border-left-color: #2D7906;
}
.todo-list .primary {
  border-left-color: #363B4D;
}
.todo-list .handle {
  display: inline-block;
  cursor: move;
  margin: 0 5px;
}
/* Chat widget (DEPRECATED - this will be removed in the next major release. Use Direct Chat instead)*/
.chat {
  padding: 5px 20px 5px 10px;
}
.chat .item {
  margin-bottom: 10px;
}
.chat .item:before,
.chat .item:after {
  display: table;
  content: " ";
}
.chat .item:after {
  clear: both;
}
.chat .item > img {
  width: 40px;
  height: 40px;
  border: 2px solid transparent;
  border-radius: 50%;
}
.chat .item > .online {
  border: 2px solid #2D7906;
}
.chat .item > .offline {
  border: 2px solid #FF0000;
}
.chat .item > .message {
  margin-left: 55px;
  margin-top: -40px;
}
.chat .item > .message > .name {
  display: block;
  font-weight: 600;
}
.chat .item > .attachment {
  border-radius: 3px;
  background: #f4f4f4;
  margin-left: 65px;
  margin-right: 15px;
  padding: 10px;
}
.chat .item > .attachment > h4 {
  margin: 0 0 5px 0;
  font-weight: 600;
  font-size: 14px;
}
.chat .item > .attachment > p,
.chat .item > .attachment > .filename {
  font-weight: 600;
  font-size: 13px;
  font-style: italic;
  margin: 0;
}
.chat .item > .attachment:before,
.chat .item > .attachment:after {
  display: table;
  content: " ";
}
.chat .item > .attachment:after {
  clear: both;
}
.box-input {
  max-width: 200px;
}
.modal .panel-body {
  color: #444;
}
/*
 * Component: Info Box
 * -------------------
 */
.info-box {
  display: block;
  min-height: 90px;
  background: #fff;
  width: 100%;
  box-shadow: 0 8px 10px rgba(17, 17, 17, 0.03);
  border-radius: 2px;
  margin-bottom: 15px;
}
.info-box small {
  font-size: 14px;
}
.info-box .progress {
  background: rgba(0, 0, 0, 0.2);
  margin: 5px -10px 5px -10px;
  height: 2px;
}
.info-box .progress,
.info-box .progress .progress-bar {
  border-radius: 0;
}
.info-box .progress .progress-bar {
  background: #fff;
}
.info-box-icon {
  border-top-left-radius: 2px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 2px;
  display: block;
  float: left;
  height: 90px;
  width: 90px;
  text-align: center;
  font-size: 45px;
  line-height: 90px;
  background: rgba(0, 0, 0, 0.2);
}
.info-box-icon > img {
  max-width: 100%;
}
.info-box-content {
  padding: 5px 10px;
  margin-left: 90px;
}
.info-box-number {
  display: block;
  font-weight: bold;
  font-size: 18px;
}
.progress-description,
.info-box-text {
  display: block;
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.info-box-text {
  text-transform: uppercase;
}
.info-box-more {
  display: block;
}
.progress-description {
  margin: 0;
}
/*
 * Component: Timeline
 * -------------------
 */
.timeline {
  position: relative;
  margin: 0 0 30px 0;
  padding: 0;
  list-style: none;
}
.timeline:before {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  width: 4px;
  background: #ddd;
  left: 31px;
  margin: 0;
  border-radius: 2px;
}
.timeline > li {
  position: relative;
  margin-right: 10px;
  margin-bottom: 15px;
}
.timeline > li:before,
.timeline > li:after {
  display: table;
  content: " ";
}
.timeline > li:after {
  clear: both;
}
.timeline > li > .timeline-item {
  box-shadow: 0 8px 10px rgba(17, 17, 17, 0.03);
  border-radius: 8px;
  margin-top: 0;
  background: #fff;
  color: #444;
  margin-left: 60px;
  margin-right: 15px;
  padding: 0;
  position: relative;
}
.timeline > li > .timeline-item > .time {
  color: #999;
  float: right;
  padding: 10px;
  font-size: 12px;
}
.timeline > li > .timeline-item > .timeline-header {
  margin: 0;
  color: #555;
  border-bottom: 1px solid #D9DBE9;
  padding: 10px;
  font-size: 16px;
  line-height: 1.1;
}
.timeline > li > .timeline-item > .timeline-header > a {
  font-weight: 600;
}
.timeline > li > .timeline-item > .timeline-body,
.timeline > li > .timeline-item > .timeline-footer {
  padding: 10px;
}
.timeline > li > .fa,
.timeline > li > .glyphicon,
.timeline > li > .ion {
  width: 30px;
  height: 30px;
  font-size: 15px;
  line-height: 30px;
  position: absolute;
  color: #666;
  background: #EFF0F7;
  border-radius: 50%;
  text-align: center;
  left: 18px;
  top: 0;
}
.timeline > .time-label > span {
  font-weight: 600;
  padding: 5px;
  display: inline-block;
  background-color: #fff;
  border-radius: 4px;
}
.timeline-inverse > li > .timeline-item {
  background: #f0f0f0;
  border: 1px solid #ddd;
  box-shadow: none;
}
.timeline-inverse > li > .timeline-item > .timeline-header {
  border-bottom-color: #ddd;
}
/*
 * Component: Button
 * -----------------
 */
.btn {
  border-radius: 4px;
  box-shadow: none;
  border: 1px solid transparent;
}
.btn.uppercase {
  text-transform: uppercase;
}
.btn.btn-flat {
  border-radius: 0;
  box-shadow: none;
  border-width: 1px;
}
.btn:active {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}
.btn:focus {
  outline: none;
}
.btn.btn-file {
  position: relative;
  overflow: hidden;
}
.btn.btn-file > input[type='file'] {
  position: absolute;
  top: 0;
  right: 0;
  min-width: 100%;
  min-height: 100%;
  font-size: 100px;
  text-align: right;
  filter: alpha(opacity=0);
  opacity: 0;
  outline: none;
  background: white;
  cursor: inherit;
  display: block;
}
.btn-default {
  background-color: #f4f4f4;
  color: #444;
  border-color: #ddd;
}
.btn-default:hover,
.btn-default:active,
.btn-default.hover {
  background-color: #e7e7e7;
}
.btn-primary {
  background-color: #363B4D;
  border-color: #2b303e;
}
.btn-primary:hover,
.btn-primary:active,
.btn-primary.hover {
  background-color: #2b303e;
}
.btn-success {
  background-color: #2D7906;
  border-color: #246105;
}
.btn-success:hover,
.btn-success:active,
.btn-success.hover {
  background-color: #246105;
}
.btn-info {
  background-color: #00c0ef;
  border-color: #00acd6;
}
.btn-info:hover,
.btn-info:active,
.btn-info.hover {
  background-color: #00acd6;
}
.btn-danger {
  background-color: #FF0000;
  border-color: #e60000;
}
.btn-danger:hover,
.btn-danger:active,
.btn-danger.hover {
  background-color: #e60000;
}
.btn-warning {
  background-color: #F4B740;
  border-color: #f3ae28;
}
.btn-warning:hover,
.btn-warning:active,
.btn-warning.hover {
  background-color: #f3ae28;
}
.btn-outline {
  border: 1px solid #fff;
  background: transparent;
  color: #fff;
}
.btn-outline:hover,
.btn-outline:focus,
.btn-outline:active {
  color: rgba(255, 255, 255, 0.7);
  border-color: rgba(255, 255, 255, 0.7);
}
.btn-link {
  box-shadow: none;
}
.btn[class*='bg-']:hover {
  box-shadow: inset 0 0 100px rgba(0, 0, 0, 0.2);
}
.btn-app {
  border-radius: 3px;
  position: relative;
  padding: 15px 5px;
  margin: 0 0 10px 10px;
  min-width: 80px;
  height: 60px;
  text-align: center;
  color: #666;
  border: 1px solid #ddd;
  background-color: #f4f4f4;
  font-size: 12px;
}
.btn-app > .fa,
.btn-app > .glyphicon,
.btn-app > .ion {
  font-size: 20px;
  display: block;
}
.btn-app:hover {
  background: #f4f4f4;
  color: #444;
  border-color: #aaa;
}
.btn-app:active,
.btn-app:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}
.btn-app > .badge {
  position: absolute;
  top: -3px;
  right: -10px;
  font-size: 10px;
  font-weight: 400;
}
/*
 * Component: Callout
 * ------------------
 */
.callout {
  border-radius: 3px;
  margin: 0 0 20px 0;
  padding: 15px 30px 15px 15px;
  border-left: 5px solid #eee;
}
.callout a {
  color: #fff;
  text-decoration: underline;
}
.callout a:hover {
  color: #eee;
}
.callout h4 {
  margin-top: 0;
  font-weight: 600;
}
.callout p:last-child {
  margin-bottom: 0;
}
.callout code,
.callout .highlight {
  background-color: #fff;
}
.callout.callout-danger {
  border-color: #cc0000;
}
.callout.callout-warning {
  border-color: #f1a510;
}
.callout.callout-info {
  border-color: #0097bc;
}
.callout.callout-success {
  border-color: #1b4804;
}
/*
 * Component: alert
 * ----------------
 */
.alert {
  border-radius: 3px;
}
.alert h4 {
  font-weight: 600;
}
.alert .icon {
  margin-right: 10px;
}
.alert .close {
  color: #000;
  filter: alpha(opacity=20);
  opacity: 0.2;
}
.alert .close:hover {
  filter: alpha(opacity=50);
  opacity: 0.5;
}
.alert a {
  color: #fff;
  text-decoration: underline;
}
.alert-success {
  border-color: #246105;
}
.alert-danger,
.alert-error {
  border-color: #e60000;
}
.alert-warning {
  border-color: #f3ae28;
}
.alert-info {
  border-color: #00acd6;
}
/*
 * Component: Nav
 * --------------
 */
.nav > li > a:hover,
.nav > li > a:active,
.nav > li > a:focus {
  color: #444;
  background: #f7f7f7;
}
/* NAV PILLS */
.nav-pills > li > a {
  border-radius: 0;
  border-top: 3px solid transparent;
  color: #444;
}
.nav-pills > li > a > .fa,
.nav-pills > li > a > .glyphicon,
.nav-pills > li > a > .ion {
  margin-right: 5px;
}
.nav-pills > li.active > a,
.nav-pills > li.active > a:hover,
.nav-pills > li.active > a:focus {
  border-top-color: #363B4D;
}
.nav-pills > li.active > a {
  font-weight: 600;
}
/* NAV STACKED */
.nav-stacked > li > a {
  border-radius: 0;
  border-top: 0;
  border-left: 3px solid transparent;
  color: #444;
}
.nav-stacked > li.active > a,
.nav-stacked > li.active > a:hover {
  background: transparent;
  color: #444;
  border-top: 0;
  border-left-color: #363B4D;
}
.nav-stacked > li.header {
  border-bottom: 1px solid #ddd;
  color: #777;
  margin-bottom: 10px;
  padding: 5px 10px;
  text-transform: uppercase;
}
/* NAV TABS */
.nav-tabs-custom {
  margin-bottom: 20px;
  background: #fff;
  box-shadow: 0 8px 10px rgba(17, 17, 17, 0.03);
  border-radius: 8px;
}
.nav-tabs-custom > .nav-tabs {
  margin: 0;
  border-bottom-color: #f4f4f4;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}
.nav-tabs-custom > .nav-tabs > li {
  border-top: 3px solid transparent;
  margin-bottom: -2px;
  margin-right: 5px;
}
.nav-tabs-custom > .nav-tabs > li > a {
  color: #444;
  border-radius: 0;
}
.nav-tabs-custom > .nav-tabs > li > a.text-muted {
  color: #999;
}
.nav-tabs-custom > .nav-tabs > li > a,
.nav-tabs-custom > .nav-tabs > li > a:hover {
  background: transparent;
  margin: 0;
}
.nav-tabs-custom > .nav-tabs > li > a:hover {
  color: #999;
}
.nav-tabs-custom > .nav-tabs > li:not(.active) > a:hover,
.nav-tabs-custom > .nav-tabs > li:not(.active) > a:focus,
.nav-tabs-custom > .nav-tabs > li:not(.active) > a:active {
  border-color: transparent;
}
.nav-tabs-custom > .nav-tabs > li.active {
  border-top-color: #363B4D;
}
.nav-tabs-custom > .nav-tabs > li.active > a,
.nav-tabs-custom > .nav-tabs > li.active:hover > a {
  background-color: #fff;
  color: #444;
}
.nav-tabs-custom > .nav-tabs > li.active > a {
  border-top-color: transparent;
  border-left-color: #f4f4f4;
  border-right-color: #f4f4f4;
}
.nav-tabs-custom > .nav-tabs > li:first-of-type {
  margin-left: 0;
}
.nav-tabs-custom > .nav-tabs > li:first-of-type.active > a {
  border-left-color: transparent;
}
.nav-tabs-custom > .nav-tabs.pull-right {
  float: none !important;
}
.nav-tabs-custom > .nav-tabs.pull-right > li {
  float: right;
}
.nav-tabs-custom > .nav-tabs.pull-right > li:first-of-type {
  margin-right: 0;
}
.nav-tabs-custom > .nav-tabs.pull-right > li:first-of-type > a {
  border-left-width: 1px;
}
.nav-tabs-custom > .nav-tabs.pull-right > li:first-of-type.active > a {
  border-left-color: #f4f4f4;
  border-right-color: transparent;
}
.nav-tabs-custom > .nav-tabs > li.header {
  line-height: 35px;
  padding: 0 10px;
  font-size: 20px;
  color: #444;
}
.nav-tabs-custom > .nav-tabs > li.header > .fa,
.nav-tabs-custom > .nav-tabs > li.header > .glyphicon,
.nav-tabs-custom > .nav-tabs > li.header > .ion {
  margin-right: 5px;
}
.nav-tabs-custom > .tab-content {
  background: #fff;
  padding: 10px;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
}
.nav-tabs-custom .dropdown.open > a:active,
.nav-tabs-custom .dropdown.open > a:focus {
  background: transparent;
  color: #999;
}
.nav-tabs-custom.tab-primary > .nav-tabs > li.active {
  border-top-color: #363B4D;
}
.nav-tabs-custom.tab-info > .nav-tabs > li.active {
  border-top-color: #00c0ef;
}
.nav-tabs-custom.tab-danger > .nav-tabs > li.active {
  border-top-color: #FF0000;
}
.nav-tabs-custom.tab-warning > .nav-tabs > li.active {
  border-top-color: #F4B740;
}
.nav-tabs-custom.tab-success > .nav-tabs > li.active {
  border-top-color: #2D7906;
}
.nav-tabs-custom.tab-default > .nav-tabs > li.active {
  border-top-color: #EFF0F7;
}
/* PAGINATION */
.pagination > li > a {
  background: #fafafa;
  color: #666;
}
.pagination.pagination-flat > li > a {
  border-radius: 0 !important;
}
/*
 * Component: Table
 * ----------------
 */
.table > thead > tr > th,
.table > tbody > tr > th,
.table > tfoot > tr > th,
.table > thead > tr > td,
.table > tbody > tr > td,
.table > tfoot > tr > td {
  border-top: 1px solid #D9DBE9;
}
.table > thead > tr > th {
  border-bottom: 2px solid #D9DBE9;
}
.table tr td .progress {
  margin-top: 5px;
}
.table-bordered {
  border: 1px solid #D9DBE9;
}
.table-bordered > thead > tr > th,
.table-bordered > tbody > tr > th,
.table-bordered > tfoot > tr > th,
.table-bordered > thead > tr > td,
.table-bordered > tbody > tr > td,
.table-bordered > tfoot > tr > td {
  border: 1px solid #D9DBE9;
}
.table-bordered > thead > tr > th,
.table-bordered > thead > tr > td {
  border-bottom-width: 2px;
}
.table.no-border,
.table.no-border td,
.table.no-border th {
  border: 0;
}
/* .text-center in tables */
table.text-center,
table.text-center td,
table.text-center th {
  text-align: center;
}
.table.align th {
  text-align: left;
}
.table.align td {
  text-align: right;
}
/*
 * Component: Label
 * ----------------
 */
.label-default {
  background-color: #EFF0F7;
  color: #444;
}
/*
 * Component: Direct Chat
 * ----------------------
 */
.direct-chat .box-body {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  position: relative;
  overflow-x: hidden;
  padding: 0;
}
.direct-chat.chat-pane-open .direct-chat-contacts {
  transform: translate(0, 0);
}
.direct-chat-messages {
  transform: translate(0, 0);
  padding: 10px;
  height: 250px;
  overflow: auto;
}
.direct-chat-msg,
.direct-chat-text {
  display: block;
}
.direct-chat-msg {
  margin-bottom: 10px;
}
.direct-chat-msg:before,
.direct-chat-msg:after {
  display: table;
  content: " ";
}
.direct-chat-msg:after {
  clear: both;
}
.direct-chat-messages,
.direct-chat-contacts {
  transition: transform 0.5s ease-in-out;
}
.direct-chat-text {
  border-radius: 5px;
  position: relative;
  padding: 5px 10px;
  background: #EFF0F7;
  border: 1px solid #EFF0F7;
  margin: 5px 0 0 50px;
  color: #444;
}
.direct-chat-text:after,
.direct-chat-text:before {
  position: absolute;
  right: 100%;
  top: 15px;
  border: solid transparent;
  border-right-color: #EFF0F7;
  content: ' ';
  height: 0;
  width: 0;
  pointer-events: none;
}
.direct-chat-text:after {
  border-width: 5px;
  margin-top: -5px;
}
.direct-chat-text:before {
  border-width: 6px;
  margin-top: -6px;
}
.right .direct-chat-text {
  margin-right: 50px;
  margin-left: 0;
}
.right .direct-chat-text:after,
.right .direct-chat-text:before {
  right: auto;
  left: 100%;
  border-right-color: transparent;
  border-left-color: #EFF0F7;
}
.direct-chat-img {
  border-radius: 50%;
  float: left;
  width: 40px;
  height: 40px;
}
.right .direct-chat-img {
  float: right;
}
.direct-chat-info {
  display: block;
  margin-bottom: 2px;
  font-size: 12px;
}
.direct-chat-name {
  font-weight: 600;
}
.direct-chat-timestamp {
  color: #999;
}
.direct-chat-contacts-open .direct-chat-contacts {
  transform: translate(0, 0);
}
.direct-chat-contacts {
  transform: translate(101%, 0);
  position: absolute;
  top: 0;
  bottom: 0;
  height: 250px;
  width: 100%;
  background: #222d32;
  color: #fff;
  overflow: auto;
}
.contacts-list > li {
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  padding: 10px;
  margin: 0;
}
.contacts-list > li:before,
.contacts-list > li:after {
  display: table;
  content: " ";
}
.contacts-list > li:after {
  clear: both;
}
.contacts-list > li:last-of-type {
  border-bottom: none;
}
.contacts-list-img {
  border-radius: 50%;
  width: 40px;
  float: left;
}
.contacts-list-info {
  margin-left: 45px;
  color: #fff;
}
.contacts-list-name,
.contacts-list-status {
  display: block;
}
.contacts-list-name {
  font-weight: 600;
}
.contacts-list-status {
  font-size: 12px;
}
.contacts-list-date {
  color: #aaa;
  font-weight: normal;
}
.contacts-list-msg {
  color: #999;
}
.direct-chat-danger .right > .direct-chat-text {
  background: #FF0000;
  border-color: #FF0000;
  color: #fff;
}
.direct-chat-danger .right > .direct-chat-text:after,
.direct-chat-danger .right > .direct-chat-text:before {
  border-left-color: #FF0000;
}
.direct-chat-primary .right > .direct-chat-text {
  background: #363B4D;
  border-color: #363B4D;
  color: #fff;
}
.direct-chat-primary .right > .direct-chat-text:after,
.direct-chat-primary .right > .direct-chat-text:before {
  border-left-color: #363B4D;
}
.direct-chat-warning .right > .direct-chat-text {
  background: #F4B740;
  border-color: #F4B740;
  color: #fff;
}
.direct-chat-warning .right > .direct-chat-text:after,
.direct-chat-warning .right > .direct-chat-text:before {
  border-left-color: #F4B740;
}
.direct-chat-info .right > .direct-chat-text {
  background: #00c0ef;
  border-color: #00c0ef;
  color: #fff;
}
.direct-chat-info .right > .direct-chat-text:after,
.direct-chat-info .right > .direct-chat-text:before {
  border-left-color: #00c0ef;
}
.direct-chat-success .right > .direct-chat-text {
  background: #2D7906;
  border-color: #2D7906;
  color: #fff;
}
.direct-chat-success .right > .direct-chat-text:after,
.direct-chat-success .right > .direct-chat-text:before {
  border-left-color: #2D7906;
}
/*
 * Component: modal
 * ----------------
 */
.modal {
  background: rgba(0, 0, 0, 0.3);
}
.modal-content {
  border-radius: 0;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.125);
  border: 0;
}
@media (min-width: 768px) {
  .modal-content {
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.125);
  }
}
.modal-header {
  border-bottom-color: #D9DBE9;
}
.modal-footer {
  border-top-color: #D9DBE9;
}
.modal-primary .modal-header,
.modal-primary .modal-footer {
  border-color: #21242f;
}
.modal-warning .modal-header,
.modal-warning .modal-footer {
  border-color: #f1a510;
}
.modal-info .modal-header,
.modal-info .modal-footer {
  border-color: #0097bc;
}
.modal-success .modal-header,
.modal-success .modal-footer {
  border-color: #1b4804;
}
.modal-danger .modal-header,
.modal-danger .modal-footer {
  border-color: #cc0000;
}
/*
 * Plugin: Select2
 * ---------------
 */
.select2-container--default.select2-container--focus,
.select2-selection.select2-container--focus,
.select2-container--default:focus,
.select2-selection:focus,
.select2-container--default:active,
.select2-selection:active {
  outline: none;
}
.select2-container--default .select2-selection--single,
.select2-selection .select2-selection--single {
  border: 1px solid #EFF0F7;
  border-radius: 6px;
  padding: 6px 12px;
  height: 34px;
}
.select2-container--default.select2-container--open {
  border-color: #363B4D;
}
.select2-dropdown {
  border: 1px solid #EFF0F7;
  border-radius: 6px;
}
.select2-container--default .select2-results__option--highlighted[aria-selected] {
  background-color: #363B4D;
  color: white;
}
.select2-results__option {
  padding: 6px 12px;
  user-select: none;
  -webkit-user-select: none;
}
.select2-container .select2-selection--single .select2-selection__rendered {
  padding-left: 0;
  padding-right: 0;
  height: auto;
  margin-top: -4px;
}
.select2-container[dir="rtl"] .select2-selection--single .select2-selection__rendered {
  padding-right: 6px;
  padding-left: 20px;
}
.select2-container--default .select2-selection--single .select2-selection__arrow {
  height: 28px;
  right: 3px;
}
.select2-container--default .select2-selection--single .select2-selection__arrow b {
  margin-top: 0;
}
.select2-dropdown .select2-search__field,
.select2-search--inline .select2-search__field {
  border: 1px solid #EFF0F7;
}
.select2-dropdown .select2-search__field:focus,
.select2-search--inline .select2-search__field:focus {
  outline: none;
  border: 1px solid #363B4D;
}
.select2-container--default .select2-results__option[aria-disabled=true] {
  color: #999;
}
.select2-container--default .select2-results__option[aria-selected=true] {
  background-color: #ddd;
}
.select2-container--default .select2-results__option[aria-selected=true],
.select2-container--default .select2-results__option[aria-selected=true]:hover {
  color: #444;
}
.select2-container--default .select2-selection--multiple {
  border: 1px solid #EFF0F7;
  border-radius: 6px;
}
.select2-container--default .select2-selection--multiple:focus {
  border-color: #363B4D;
}
.select2-container--default.select2-container--focus .select2-selection--multiple {
  border-color: #EFF0F7;
}
.select2-container--default .select2-selection--multiple .select2-selection__choice {
  background-color: #363B4D;
  border-color: #2b303e;
  padding: 1px 10px;
  color: #fff;
}
.select2-container--default .select2-selection--multiple .select2-selection__choice__remove {
  margin-right: 5px;
  color: rgba(255, 255, 255, 0.7);
}
.select2-container--default .select2-selection--multiple .select2-selection__choice__remove:hover {
  color: #fff;
}
.select2-container .select2-selection--single .select2-selection__rendered {
  padding-right: 10px;
}
.select2-selection {
  display: flex !important;
  height: auto !important;
  padding: 6px 34px 6px 10px !important;
  cursor: pointer;
  background-image: url('/assets/svg/chevron-down.svg');
  background-repeat: no-repeat;
  background-position: right 10px center;
  background-size: 16px 14px;
  font-weight: 700;
  border-color: #D9DBE9 !important;
  border-radius: 6px !important;
  box-shadow: none;
  color: #363B4D;
}
.select2-selection .select2-selection__rendered {
  margin: 0 !important;
  padding: 0 32px 0 0 !important;
  line-height: 1.4 !important;
  color: inherit !important;
}
.select2-selection .select2-selection__rendered .select2-selection__clear {
  position: absolute !important;
  right: 34px !important;
  top: 50% !important;
  transform: translateY(-50%);
  width: 0;
  margin: 0 !important;
  color: transparent;
  opacity: 0.5;
  font-size: 0 !important;
}
.select2-selection .select2-selection__rendered .select2-selection__clear:before {
  content: '\f00d';
  font-family: "Font Awesome 6 Pro";
  position: relative;
  right: 28px;
  width: 28px;
  height: 28px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  color: #363B4D;
  cursor: pointer;
}
.select2-selection .select2-selection__rendered .select2-selection__clear:hover {
  opacity: 1;
}
.select2-selection .select2-selection__rendered .select2-selection__placeholder {
  font-weight: 400;
  color: #707893 !important;
  line-height: 1.4;
}
.select2-selection ul.select2-selection__rendered {
  display: flex !important;
  flex-wrap: wrap !important;
  align-items: start !important;
  gap: 4px !important;
}
.select2-selection ul.select2-selection__rendered .select2-selection__choice {
  box-shadow: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  background-color: #f4f4f4;
  color: #444;
  border-color: #ddd;
  color: #363B4D;
  background-color: #EFF0F7;
  border-color: #e8eaf4;
  padding: 4px 6px;
  font-size: 12px;
  line-height: 1;
  border-radius: 4px;
  min-height: 28px;
  border-radius: 6px;
  border: 1px solid transparent;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
  position: relative;
  height: 22px;
  margin: 0 !important;
  padding: 4px 32px 4px 6px !important;
}
.select2-selection ul.select2-selection__rendered .select2-selection__choice.uppercase {
  text-transform: uppercase;
}
.select2-selection ul.select2-selection__rendered .select2-selection__choice.btn-flat {
  border-radius: 0;
  box-shadow: none;
  border-width: 1px;
}
.select2-selection ul.select2-selection__rendered .select2-selection__choice:active {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}
.select2-selection ul.select2-selection__rendered .select2-selection__choice:focus {
  outline: none;
}
.select2-selection ul.select2-selection__rendered .select2-selection__choice.btn-file {
  position: relative;
  overflow: hidden;
}
.select2-selection ul.select2-selection__rendered .select2-selection__choice.btn-file > input[type='file'] {
  position: absolute;
  top: 0;
  right: 0;
  min-width: 100%;
  min-height: 100%;
  font-size: 100px;
  text-align: right;
  filter: alpha(opacity=0);
  opacity: 0;
  outline: none;
  background: white;
  cursor: inherit;
  display: block;
}
.select2-selection ul.select2-selection__rendered .select2-selection__choice:focus {
  outline: #363B4D solid 1px;
  outline-offset: -2px;
}
.select2-selection ul.select2-selection__rendered .select2-selection__choice.kv-hidden {
  display: none;
}
.select2-selection ul.select2-selection__rendered .select2-selection__choice:hover,
.select2-selection ul.select2-selection__rendered .select2-selection__choice:active,
.select2-selection ul.select2-selection__rendered .select2-selection__choice.hover {
  background-color: #e7e7e7;
}
.select2-selection ul.select2-selection__rendered .select2-selection__choice:focus,
.select2-selection ul.select2-selection__rendered .select2-selection__choice.focus {
  color: #363B4D;
  background-color: #cdd0e6;
  border-color: #939ac9;
}
.select2-selection ul.select2-selection__rendered .select2-selection__choice:hover {
  color: #363B4D;
  background-color: #cdd0e6;
  border-color: #bfc3df;
}
.select2-selection ul.select2-selection__rendered .select2-selection__choice:active,
.select2-selection ul.select2-selection__rendered .select2-selection__choice.active,
.open > .dropdown-toggle.select2-selection ul.select2-selection__rendered .select2-selection__choice {
  color: #363B4D;
  background-color: #cdd0e6;
  background-image: none;
  border-color: #bfc3df;
}
.select2-selection ul.select2-selection__rendered .select2-selection__choice:active:hover,
.select2-selection ul.select2-selection__rendered .select2-selection__choice.active:hover,
.open > .dropdown-toggle.select2-selection ul.select2-selection__rendered .select2-selection__choice:hover,
.select2-selection ul.select2-selection__rendered .select2-selection__choice:active:focus,
.select2-selection ul.select2-selection__rendered .select2-selection__choice.active:focus,
.open > .dropdown-toggle.select2-selection ul.select2-selection__rendered .select2-selection__choice:focus,
.select2-selection ul.select2-selection__rendered .select2-selection__choice:active.focus,
.select2-selection ul.select2-selection__rendered .select2-selection__choice.active.focus,
.open > .dropdown-toggle.select2-selection ul.select2-selection__rendered .select2-selection__choice.focus {
  color: #363B4D;
  background-color: #b5bada;
  border-color: #939ac9;
}
.select2-selection ul.select2-selection__rendered .select2-selection__choice.disabled:hover,
.select2-selection ul.select2-selection__rendered .select2-selection__choice[disabled]:hover,
fieldset[disabled] .select2-selection ul.select2-selection__rendered .select2-selection__choice:hover,
.select2-selection ul.select2-selection__rendered .select2-selection__choice.disabled:focus,
.select2-selection ul.select2-selection__rendered .select2-selection__choice[disabled]:focus,
fieldset[disabled] .select2-selection ul.select2-selection__rendered .select2-selection__choice:focus,
.select2-selection ul.select2-selection__rendered .select2-selection__choice.disabled.focus,
.select2-selection ul.select2-selection__rendered .select2-selection__choice[disabled].focus,
fieldset[disabled] .select2-selection ul.select2-selection__rendered .select2-selection__choice.focus {
  background-color: #EFF0F7;
  border-color: #e8eaf4;
}
.select2-selection ul.select2-selection__rendered .select2-selection__choice .badge {
  color: #EFF0F7;
  background-color: #363B4D;
}
.select2-selection ul.select2-selection__rendered .select2-selection__choice .badge {
  background-color: #F4B740;
}
.select2-selection ul.select2-selection__rendered .select2-selection__choice i {
  font-size: 14px;
}
.select2-selection ul.select2-selection__rendered .select2-selection__choice.disabled {
  cursor: default;
}
.select2-selection ul.select2-selection__rendered .select2-selection__choice .chip-remove {
  font-size: 16px;
  opacity: 0.5;
}
.select2-selection ul.select2-selection__rendered .select2-selection__choice .chip-remove:hover {
  cursor: pointer;
  opacity: 1;
}
.select2-selection ul.select2-selection__rendered .select2-selection__choice .select2-selection__choice__remove {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 0;
  margin: 0;
  color: transparent;
  opacity: 1;
  font-size: 0;
  line-height: 1;
}
.select2-selection ul.select2-selection__rendered .select2-selection__choice .select2-selection__choice__remove:before {
  content: '\f057';
  font-family: "Font Awesome 6 Pro";
  position: relative;
  right: 22px;
  display: inline-flex;
  justify-content: center;
  width: 16px;
  height: 16px;
  color: #363B4D;
  font-size: 16px;
  opacity: 0.5;
  cursor: pointer;
}
.select2-selection ul.select2-selection__rendered .select2-selection__choice .select2-selection__choice__remove:hover:before {
  opacity: 1;
}
.select2-selection ul.select2-selection__rendered .select2-search {
  height: auto !important;
  display: flex !important;
  align-items: center !important;
}
.select2-selection ul.select2-selection__rendered .select2-search .select2-search__field {
  height: auto !important;
  margin-top: 0 !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  color: #363B4D;
}
.select2-selection ul.select2-selection__rendered .select2-search .select2-search__field::placeholder {
  color: #A7AEC9;
}
.select2-selection ul.select2-selection__rendered .select2-search .select2-search__field:focus {
  border: none !important;
}
.select2-selection .select2-selection__arrow {
  display: none;
}
.select2-container {
  display: block !important;
  width: auto !important;
}
.select2-container .select2-dropdown {
  border-color: #D9DBE9 !important;
}
.select2-container .select2-dropdown .select2-results .select2-results__options .select2-results__option[aria-selected="true"] {
  color: #363B4D !important;
  background-color: #f6f6fa !important;
}
.select2-container .select2-dropdown .select2-results .select2-results__options .select2-results__option--highlighted,
.select2-container .select2-dropdown .select2-results .select2-results__options .select2-results__option--highlighted.select2-results__option[aria-selected="true"] {
  color: #363B4D !important;
  background-color: #EFF0F7 !important;
}
select.select2-without-clear + span.select2 .select2-selection ul.select2-selection__rendered {
  padding-right: 0 !important;
}
.select2-selection.select2-selection--multiple {
  padding-top: 4px !important;
  padding-bottom: 4px !important;
  padding-left: 6px !important;
}
.select2-selection.select2-selection--multiple .select2-selection__rendered {
  min-height: 24px !important;
}
.select2-selection.select2-selection--multiple .select2-selection__rendered .select2-search__field {
  min-height: 24px !important;
}
.select2-selection.select2-selection--multiple .select2-selection__rendered .select2-selection__choice {
  min-height: 24px !important;
}
.form-group.has-error .select2-selection {
  border-color: #FF0000 !important;
}
/*
 * General: Miscellaneous
 * ----------------------
 */
.pad {
  padding: 10px;
}
.margin {
  margin: 10px;
}
.margin-bottom {
  margin-bottom: 20px;
}
.margin-bottom-none {
  margin-bottom: 0;
}
.margin-r-5 {
  margin-right: 5px;
}
.inline {
  display: inline;
}
.description-block {
  display: block;
  margin: 10px 0;
  text-align: center;
}
.description-block.margin-bottom {
  margin-bottom: 25px;
}
.description-block > .description-header {
  margin: 0;
  padding: 0;
  font-weight: 600;
  font-size: 16px;
}
.description-block > .description-text {
  text-transform: uppercase;
}
.bg-red,
.bg-yellow,
.bg-aqua,
.bg-blue,
.bg-light-blue,
.bg-green,
.bg-navy,
.bg-teal,
.bg-olive,
.bg-lime,
.bg-orange,
.bg-fuchsia,
.bg-purple,
.bg-maroon,
.bg-black,
.bg-red-active,
.bg-yellow-active,
.bg-aqua-active,
.bg-blue-active,
.bg-light-blue-active,
.bg-green-active,
.bg-navy-active,
.bg-teal-active,
.bg-olive-active,
.bg-lime-active,
.bg-orange-active,
.bg-fuchsia-active,
.bg-purple-active,
.bg-maroon-active,
.bg-black-active,
.callout.callout-danger,
.callout.callout-warning,
.callout.callout-info,
.callout.callout-success,
.alert-success,
.alert-danger,
.alert-error,
.alert-warning,
.alert-info,
.label-danger,
.label-info,
.label-warning,
.label-primary,
.label-success,
.modal-primary .modal-body,
.modal-primary .modal-header,
.modal-primary .modal-footer,
.modal-warning .modal-body,
.modal-warning .modal-header,
.modal-warning .modal-footer,
.modal-info .modal-body,
.modal-info .modal-header,
.modal-info .modal-footer,
.modal-success .modal-body,
.modal-success .modal-header,
.modal-success .modal-footer,
.modal-danger .modal-body,
.modal-danger .modal-header,
.modal-danger .modal-footer {
  color: #fff !important;
}
.bg-gray {
  color: #000;
  background-color: #EFF0F7 !important;
}
.bg-gray-light {
  background-color: #f7f7f7;
}
.bg-black {
  background-color: #111 !important;
}
.bg-red,
.callout.callout-danger,
.alert-danger,
.alert-error,
.label-danger,
.modal-danger .modal-body {
  background-color: #FF0000 !important;
}
.bg-yellow,
.callout.callout-warning,
.alert-warning,
.label-warning,
.modal-warning .modal-body {
  background-color: #F4B740 !important;
}
.bg-aqua,
.callout.callout-info,
.alert-info,
.label-info,
.modal-info .modal-body {
  background-color: #00c0ef !important;
}
.bg-blue {
  background-color: #0073b7 !important;
}
.bg-light-blue,
.label-primary,
.modal-primary .modal-body {
  background-color: #363B4D !important;
}
.bg-green,
.callout.callout-success,
.alert-success,
.label-success,
.modal-success .modal-body {
  background-color: #2D7906 !important;
}
.bg-navy {
  background-color: #001F3F !important;
}
.bg-teal {
  background-color: #39CCCC !important;
}
.bg-olive {
  background-color: #3D9970 !important;
}
.bg-lime {
  background-color: #01FF70 !important;
}
.bg-orange {
  background-color: #FF851B !important;
}
.bg-fuchsia {
  background-color: #F012BE !important;
}
.bg-purple {
  background-color: #605ca8 !important;
}
.bg-maroon {
  background-color: #D81B60 !important;
}
.bg-gray-active {
  color: #000;
  background-color: #cdd0e6 !important;
}
.bg-black-active {
  background-color: #000000 !important;
}
.bg-red-active,
.modal-danger .modal-header,
.modal-danger .modal-footer {
  background-color: #e00000 !important;
}
.bg-yellow-active,
.modal-warning .modal-header,
.modal-warning .modal-footer {
  background-color: #f2ac23 !important;
}
.bg-aqua-active,
.modal-info .modal-header,
.modal-info .modal-footer {
  background-color: #00a7d0 !important;
}
.bg-blue-active {
  background-color: #005384 !important;
}
.bg-light-blue-active,
.modal-primary .modal-header,
.modal-primary .modal-footer {
  background-color: #292d3b !important;
}
.bg-green-active,
.modal-success .modal-header,
.modal-success .modal-footer {
  background-color: #246105 !important;
}
.bg-navy-active {
  background-color: #001a35 !important;
}
.bg-teal-active {
  background-color: #30bbbb !important;
}
.bg-olive-active {
  background-color: #368763 !important;
}
.bg-lime-active {
  background-color: #00e765 !important;
}
.bg-orange-active {
  background-color: #ff7701 !important;
}
.bg-fuchsia-active {
  background-color: #db0ead !important;
}
.bg-purple-active {
  background-color: #555299 !important;
}
.bg-maroon-active {
  background-color: #ca195a !important;
}
[class^="bg-"].disabled {
  filter: alpha(opacity=65);
  opacity: 0.65;
}
.text-red {
  color: #FF0000 !important;
}
.text-yellow {
  color: #F4B740 !important;
}
.text-aqua {
  color: #00c0ef !important;
}
.text-blue {
  color: #0073b7 !important;
}
.text-black {
  color: #111 !important;
}
.text-light-blue {
  color: #363B4D !important;
}
.text-green {
  color: #2D7906 !important;
}
.text-gray {
  color: #EFF0F7 !important;
}
.text-navy {
  color: #001F3F !important;
}
.text-teal {
  color: #39CCCC !important;
}
.text-olive {
  color: #3D9970 !important;
}
.text-lime {
  color: #01FF70 !important;
}
.text-orange {
  color: #FF851B !important;
}
.text-fuchsia {
  color: #F012BE !important;
}
.text-purple {
  color: #605ca8 !important;
}
.text-maroon {
  color: #D81B60 !important;
}
.link-muted {
  color: #8990c4;
}
.link-muted:hover,
.link-muted:focus {
  color: #6770b3;
}
.link-black {
  color: #666;
}
.link-black:hover,
.link-black:focus {
  color: #999;
}
.hide {
  display: none !important;
}
.no-border {
  border: 0 !important;
}
.no-padding {
  padding: 0 !important;
}
.no-margin {
  margin: 0 !important;
}
.no-shadow {
  box-shadow: none !important;
}
.list-unstyled,
.chart-legend,
.contacts-list {
  list-style: none;
  margin: 0;
  padding: 0;
}
.list-group-unbordered > .list-group-item {
  border-left: 0;
  border-right: 0;
  border-radius: 0;
  padding-left: 0;
  padding-right: 0;
}
.flat {
  border-radius: 0 !important;
}
.text-bold,
.text-bold.table td,
.text-bold.table th {
  font-weight: 700;
}
.text-sm {
  font-size: 12px;
}
.jqstooltip {
  padding: 5px !important;
  width: auto !important;
  height: auto !important;
}
.bg-teal-gradient {
  background: #39CCCC !important;
  background: -o-linear-gradient(#7adddd, #39CCCC) !important;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#7adddd', endColorstr='#39CCCC', GradientType=0) !important;
  color: #fff;
}
.bg-light-blue-gradient {
  background: #363B4D !important;
  background: -o-linear-gradient(#4f5771, #363B4D) !important;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#4f5771', endColorstr='#363B4D', GradientType=0) !important;
  color: #fff;
}
.bg-blue-gradient {
  background: #0073b7 !important;
  background: -o-linear-gradient(#0089db, #0073b7) !important;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#0089db', endColorstr='#0073b7', GradientType=0) !important;
  color: #fff;
}
.bg-aqua-gradient {
  background: #00c0ef !important;
  background: -o-linear-gradient(#14d1ff, #00c0ef) !important;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#14d1ff', endColorstr='#00c0ef', GradientType=0) !important;
  color: #fff;
}
.bg-yellow-gradient {
  background: #F4B740 !important;
  background: -o-linear-gradient(#f8d48d, #F4B740) !important;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f8d48d', endColorstr='#F4B740', GradientType=0) !important;
  color: #fff;
}
.bg-purple-gradient {
  background: #605ca8 !important;
  background: -o-linear-gradient(#9491c4, #605ca8) !important;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#9491c4', endColorstr='#605ca8', GradientType=0) !important;
  color: #fff;
}
.bg-green-gradient {
  background: #2D7906 !important;
  background: -o-linear-gradient(#3a9b08, #2D7906) !important;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#3a9b08', endColorstr='#2D7906', GradientType=0) !important;
  color: #fff;
}
.bg-red-gradient {
  background: #FF0000 !important;
  background: -o-linear-gradient(#ff3333, #FF0000) !important;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff3333', endColorstr='#FF0000', GradientType=0) !important;
  color: #fff;
}
.bg-black-gradient {
  background: #111 !important;
  background: -o-linear-gradient(#2b2b2b, #111) !important;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#2b2b2b', endColorstr='#111', GradientType=0) !important;
  color: #fff;
}
.bg-maroon-gradient {
  background: #D81B60 !important;
  background: -o-linear-gradient(#e73f7c, #D81B60) !important;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#e73f7c', endColorstr='#D81B60', GradientType=0) !important;
  color: #fff;
}
.description-block .description-icon {
  font-size: 16px;
}
.no-pad-top {
  padding-top: 0;
}
.position-static {
  position: static !important;
}
.list-header {
  font-size: 15px;
  padding: 10px 4px;
  font-weight: bold;
  color: #666;
}
.list-seperator {
  height: 1px;
  background: #D9DBE9;
  margin: 15px 0 9px 0;
}
.list-link > a {
  padding: 4px;
  color: #777;
}
.list-link > a:hover {
  color: #222;
}
.font-light {
  font-weight: 300;
}
.user-block:before,
.user-block:after {
  display: table;
  content: " ";
}
.user-block:after {
  clear: both;
}
.user-block img {
  width: 40px;
  height: 40px;
  float: left;
}
.user-block .username,
.user-block .description,
.user-block .comment {
  display: block;
  margin-left: 50px;
}
.user-block .username {
  font-size: 16px;
  font-weight: 600;
}
.user-block .description {
  color: #999;
  font-size: 13px;
}
.user-block.user-block-sm .username,
.user-block.user-block-sm .description,
.user-block.user-block-sm .comment {
  margin-left: 40px;
}
.user-block.user-block-sm .username {
  font-size: 14px;
}
.img-sm,
.img-md,
.img-lg,
.box-comments .box-comment img,
.user-block.user-block-sm img {
  float: left;
}
.img-sm,
.box-comments .box-comment img,
.user-block.user-block-sm img {
  width: 30px !important;
  height: 30px !important;
}
.img-sm + .img-push {
  margin-left: 40px;
}
.img-md {
  width: 60px;
  height: 60px;
}
.img-md + .img-push {
  margin-left: 70px;
}
.img-lg {
  width: 100px;
  height: 100px;
}
.img-lg + .img-push {
  margin-left: 110px;
}
.img-bordered {
  border: 3px solid #EFF0F7;
  padding: 3px;
}
.img-bordered-sm {
  border: 2px solid #EFF0F7;
  padding: 2px;
}
.attachment-block {
  border: 1px solid #D9DBE9;
  padding: 5px;
  margin-bottom: 10px;
  background: #f7f7f7;
}
.attachment-block .attachment-img {
  max-width: 100px;
  max-height: 100px;
  height: auto;
  float: left;
}
.attachment-block .attachment-pushed {
  margin-left: 110px;
}
.attachment-block .attachment-heading {
  margin: 0;
}
.attachment-block .attachment-text {
  color: #555;
}
.connectedSortable {
  min-height: 100px;
}
.ui-helper-hidden-accessible {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}
.sort-highlight {
  background: #f4f4f4;
  border: 1px dashed #ddd;
  margin-bottom: 10px;
}
.full-opacity-hover {
  filter: alpha(opacity=65);
  opacity: 0.65;
}
.full-opacity-hover:hover {
  filter: alpha(opacity=100);
  opacity: 1;
}
.chart {
  position: relative;
  overflow: hidden;
  width: 100%;
}
.chart svg,
.chart canvas {
  width: 100% !important;
}
.margin-bottom-small {
  margin-bottom: 10px;
}
.bg-primary {
  color: #fff !important;
  background-color: #363B4D !important;
}
/*
 * Misc: print
 * -----------
 */
@media print {
  .no-print,
  .main-sidebar,
  .left-side,
  .main-header,
  .content-header {
    display: none !important;
  }
  .content-wrapper,
  .right-side,
  .main-footer {
    margin-left: 0 !important;
    min-height: 0 !important;
    transform: translate(0, 0) !important;
  }
  .fixed .content-wrapper,
  .fixed .right-side {
    padding-top: 0 !important;
  }
  .invoice {
    width: 100%;
    border: 0;
    margin: 0;
    padding: 0;
  }
  .invoice-col {
    float: left;
    width: 33.3333333%;
  }
  .table-responsive {
    overflow: auto;
  }
  .table-responsive > .table tr th,
  .table-responsive > .table tr td {
    white-space: normal !important;
  }
}
.skin-maroon .main-header .navbar {
  background-color: #f4f4fa;
}
.skin-maroon .main-header .navbar .nav > li > a {
  color: #363B4D;
}
.skin-maroon .main-header .navbar .nav > li > a:hover,
.skin-maroon .main-header .navbar .nav > li > a:active,
.skin-maroon .main-header .navbar .nav > li > a:focus,
.skin-maroon .main-header .navbar .nav .open > a,
.skin-maroon .main-header .navbar .nav .open > a:hover,
.skin-maroon .main-header .navbar .nav .open > a:focus,
.skin-maroon .main-header .navbar .nav > .active > a {
  background: rgba(0, 0, 0, 0.1);
  color: #363B4D;
}
.skin-maroon .main-header .navbar .sidebar-toggle {
  color: #363B4D;
}
.skin-maroon .main-header .navbar .sidebar-toggle:hover {
  color: #363B4D;
  background: rgba(0, 0, 0, 0.1);
}
.skin-maroon .main-header .navbar .sidebar-toggle:hover {
  background-color: #e2e2f2;
}
.skin-maroon .main-header .logo {
  background-color: #f4f4fa;
  color: #363B4D;
  border-bottom: 0 solid transparent;
}
.skin-maroon .main-header .logo:hover {
  background-color: #f0f0f8;
}
.skin-maroon .wrapper,
.skin-maroon .main-sidebar,
.skin-maroon .left-side {
  background-color: #f4f4fa;
}
.skin-maroon .content-wrapper,
.skin-maroon .main-footer {
  border-left: 1px solid #EFF0F7;
}
.skin-maroon .user-panel > .info,
.skin-maroon .user-panel > .info > a {
  color: #363B4D;
}
.skin-maroon .sidebar-menu > li {
  transition: border-left-color 0.3s ease;
}
.skin-maroon .sidebar-menu > li.header {
  color: #6b7597;
  background: #f4f4fa;
}
.skin-maroon .sidebar-menu > li > a {
  border-left: 3px solid transparent;
  font-weight: 600;
}
.skin-maroon .sidebar-menu > li:hover > a,
.skin-maroon .sidebar-menu > li.active > a {
  color: #363B4D;
  background: #e9e9f5;
}
.skin-maroon .sidebar-menu > li.active {
  border-left-color: #A7AEC9;
}
.skin-maroon .sidebar-menu > li.active > a {
  font-weight: 600;
}
.skin-maroon .sidebar-menu > li > .treeview-menu {
  background: #e6e6f4;
}
.skin-maroon .sidebar a {
  color: #363B4D;
}
.skin-maroon .sidebar a:hover {
  text-decoration: none;
}
.skin-maroon .treeview-menu > li > a {
  color: #363B4D;
}
.skin-maroon .treeview-menu > li.active > a,
.skin-maroon .treeview-menu > li > a:hover {
  color: #363B4D;
}
.skin-maroon .treeview-menu > li.active > a {
  font-weight: 600;
}
.skin-maroon .sidebar-form {
  border-radius: 3px;
  border: 1px solid #EFF0F7;
  margin: 10px 10px;
}
.skin-maroon .sidebar-form input[type="text"],
.skin-maroon .sidebar-form .btn {
  box-shadow: none;
  background-color: #fff;
  border: 1px solid transparent;
  height: 35px;
}
.skin-maroon .sidebar-form input[type="text"] {
  color: #666;
  border-top-left-radius: 2px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 2px;
}
.skin-maroon .sidebar-form input[type="text"]:focus,
.skin-maroon .sidebar-form input[type="text"]:focus + .input-group-btn .btn {
  background-color: #fff;
  color: #666;
}
.skin-maroon .sidebar-form input[type="text"]:focus + .input-group-btn .btn {
  border-left-color: #fff;
}
.skin-maroon .sidebar-form .btn {
  color: #999;
  border-top-left-radius: 0;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
  border-bottom-left-radius: 0;
}
@media (min-width: 768px) {
  .skin-maroon.sidebar-mini.sidebar-collapse .sidebar-menu > li > .treeview-menu {
    border-left: 1px solid #EFF0F7;
  }
}
.skin-maroon .content-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 12px;
  background: transparent;
}
.skin-maroon:not(.sidebar-collapse) .content-wrapper {
  border-left: none;
}
.skin-maroon.sidebar-collapse .navbar {
  border-left: 1px solid #EFF0F7;
}
.skin-maroon .sidebar-menu > li > a,
.skin-maroon .sidebar-menu > li.active > a {
  font-weight: 400;
}
.skin-maroon .sidebar-menu > li:hover > a,
.skin-maroon .sidebar-menu > li.active > a {
  border-left-color: #363B4D;
}
.skin-maroon .sidebar-menu > li.active > a,
.skin-maroon .sidebar-menu > li.active > a > i {
  background-image: linear-gradient(to right, #ac005d, tomato);
  -webkit-background-clip: text;
  color: transparent;
}
.skin-maroon .treeview-menu > li.active > a {
  font-weight: 400;
}
.skin-maroon .treeview-menu > li.active > a,
.skin-maroon .treeview-menu > li.active > a > i {
  background-image: linear-gradient(to right, #ac005d, tomato);
  -webkit-background-clip: text;
  color: transparent;
}
.dark-theme .skin-maroon .wrapper,
.dark-theme .skin-maroon .main-sidebar,
.dark-theme .skin-maroon .left-side {
  background-color: #363B4D;
}
.dark-theme .skin-maroon .user-panel > .info,
.dark-theme .skin-maroon .user-panel > .info > a {
  color: #fff;
}
.dark-theme .skin-maroon .sidebar-menu > li.header {
  color: #606989;
  background: #2e3241;
}
.dark-theme .skin-maroon .sidebar-menu > li > a {
  border-left: 3px solid transparent;
}
.dark-theme .skin-maroon .sidebar-menu > li:hover > a,
.dark-theme .skin-maroon .sidebar-menu > li.active > a {
  color: #FCFCFC;
  background: #2e3241;
  border-left-color: #DF0079;
}
.dark-theme .skin-maroon .sidebar-menu > li > .treeview-menu {
  margin: 0 1px;
  background: #474d65;
}
.dark-theme .skin-maroon .sidebar a {
  color: #FCFCFC;
}
.dark-theme .skin-maroon .sidebar a:hover {
  text-decoration: none;
}
.dark-theme .skin-maroon .treeview-menu > li > a {
  color: #FCFCFC;
}
.dark-theme .skin-maroon .treeview-menu > li.active > a,
.dark-theme .skin-maroon .treeview-menu > li > a:hover {
  color: #FCFCFC;
}
.dark-theme .skin-maroon .sidebar-form {
  border-radius: 3px;
  border: 1px solid #4b526b;
  margin: 10px 10px;
}
.dark-theme .skin-maroon .sidebar-form input[type="text"],
.dark-theme .skin-maroon .sidebar-form .btn {
  box-shadow: none;
  background-color: #4b526b;
  border: 1px solid transparent;
  height: 35px;
}
.dark-theme .skin-maroon .sidebar-form input[type="text"] {
  color: #666;
  border-top-left-radius: 2px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 2px;
}
.dark-theme .skin-maroon .sidebar-form input[type="text"]:focus,
.dark-theme .skin-maroon .sidebar-form input[type="text"]:focus + .input-group-btn .btn {
  background-color: #fff;
  color: #666;
}
.dark-theme .skin-maroon .sidebar-form input[type="text"]:focus + .input-group-btn .btn {
  border-left-color: #fff;
}
.dark-theme .skin-maroon .sidebar-form .btn {
  color: #999;
  border-top-left-radius: 0;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
  border-bottom-left-radius: 0;
}
.dark-theme .skin-maroon .sidebar-menu > li.active > a,
.dark-theme .skin-maroon .sidebar-menu > li.active > a > i {
  background-image: unset;
  color: #FCFCFC;
}
.dark-theme .skin-maroon .sidebar-menu > li > .treeview-menu {
  margin: 0;
}
.dark-theme .skin-maroon .treeview-menu > li.active > a,
.dark-theme .skin-maroon .treeview-menu > li.active > a > i {
  background-image: unset;
  color: #FCFCFC;
}
