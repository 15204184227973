@import "~admin-lte/build/less/miscellaneous";

.margin-bottom-small {
  margin-bottom: 10px;
}

// Background colors
.bg-primary {
  color: #fff !important;
  background-color: @brand-primary !important;
}
