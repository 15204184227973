@import (reference) "../../bootstrap/variables";
@import (reference) '../../common.less';

.skin-maroon {
  //Navbar
  .main-header {
    .navbar {
      .navbar-variant(@body-bg; @text-color; @text-color);

      .sidebar-toggle:hover {
        background-color: darken(@body-bg, 5%);
      }
    }

    //Logo
    .logo {
      .logo-variant(@body-bg; @text-color);
    }
  }

  // Create the sidebar skin
  .skin-light-sidebar(@text-icon);

  //Content Header
  .content-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: 12px;
    background: transparent;
  }

  &:not(.sidebar-collapse) .content-wrapper {
      border-left: none;
  }

  &.sidebar-collapse {
      .navbar {
          border-left: 1px solid @brand-gray;
      }
  }

  .sidebar-menu > li {
    > a, &.active > a {
      font-weight: 400;
    }

    &:hover > a,
    &.active > a {
      border-left-color: @brand-primary;
    }

    &.active > a,
    &.active > a > i {
      background-image: linear-gradient(to right, darken(#DF0079, 10%), tomato);// base is @brand-secondary
      -webkit-background-clip: text;
      color: transparent;
    }
  }

  .treeview-menu > li.active > a {
    font-weight: 400;
  }

  .treeview-menu > li.active > a,
  .treeview-menu > li.active > a > i {
    background-image: linear-gradient(to right, darken(#DF0079, 10%), tomato);// base is @brand-secondary
    -webkit-background-clip: text;
    color: transparent;
  }
}

// Dark Sidebar Styles Override
.dark-theme {
  .skin-maroon {
    .skin-dark-sidebar(@brand-secondary);

    .sidebar-menu > li {
      &.active > a,
      &.active > a > i {
        background-image: unset;
        color: @text-light;
      }

      > .treeview-menu {
        margin: 0;
      }
    }

    .treeview-menu > li.active > a,
    .treeview-menu > li.active > a > i {
      background-image: unset;
      color: @text-light;
    }
  }
}
