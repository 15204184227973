@import '~admin-lte/build/less/select2';
@import (reference) "/assets/css/widgets/chip";

.select2-selection {
  display: flex !important;
  height: auto !important;
  padding: 6px 34px 6px 10px !important;
  cursor: pointer;
  background-image: url('/assets/svg/chevron-down.svg');
  background-repeat: no-repeat;
  background-position: right 10px center;
  background-size: 16px 14px;
  font-weight: 700;
  border-color: @border-gray !important;
  border-radius: @border-radius-base !important;
  box-shadow: none;
  color: @text-color;

  .select2-selection__rendered {
    margin: 0 !important;
    padding: 0 32px 0 0 !important;
    line-height: 1.4 !important;
    color: inherit !important;

    .select2-selection__clear {
      position: absolute !important;
      right: 34px !important;
      top: 50% !important;
      transform: translateY(-50%);
      width: 0;
      margin: 0 !important;
      color: transparent;
      opacity: 0.5;
      font-size: 0 !important; // prevents the "x" text from taking up space

      &:before {
        content: '\f00d';
        font-family: "Font Awesome 6 Pro";
        position: relative;
        right: 28px;
        width: 28px;
        height: 28px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        font-size: 16px;
        color: @text-color;
        cursor: pointer;
      }

      &:hover {
        opacity: 1;
      }
    }

    .select2-selection__placeholder {
      font-weight: 400;
      color: @text-gray !important;
      line-height: 1.4;
    }
  }

  ul.select2-selection__rendered {
    .chip-wrapper !important;

    .select2-selection__choice {
      .chip;
      position: relative;
      height: 22px;
      margin: 0 !important;
      padding: 4px 32px 4px 6px !important;

      .select2-selection__choice__remove {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 0;
        margin: 0;
        color: transparent;
        opacity: 1;
        font-size: 0; // prevents the "x" text from taking up space
        line-height: 1;

        &:before {
          content: '\f057';
          font-family: "Font Awesome 6 Pro";
          position: relative;
          right: 22px;
          display: inline-flex;
          justify-content: center;
          width: 16px;
          height: 16px;
          color: @text-color;
          font-size: 16px;
          opacity: 0.5;
          cursor: pointer;
        }

        &:hover:before {
          opacity: 1;
        }
      }
    }

    .select2-search {
      height: auto !important;
      display: flex !important;
      align-items: center !important;

      .select2-search__field {
        height: auto !important;
        margin-top: 0 !important;
        padding-top: 0 !important;
        padding-bottom: 0 !important;
        color: #363B4D;

        &::placeholder {
          color: #A7AEC9;
        }

        &:focus {
          border: none !important;
        }
      }
    }
  }

  .select2-selection__arrow {
    display: none;
  }
}

.select2-container {
  display: block !important;
  width: auto !important;

  .select2-dropdown {
    border-color: @border-gray !important;

    .select2-results {
      .select2-results__options {
        .select2-results__option[aria-selected="true"] {
          color: @text-color !important;
          background-color: lighten(@brand-gray, 2%) !important;
        }

        .select2-results__option--highlighted,
        .select2-results__option--highlighted.select2-results__option[aria-selected="true"] {
          color: @text-color !important;
          background-color: @brand-gray !important;
        }
      }
    }
  }
}

// Modifications for special cases

select.select2-without-clear + span.select2 {
  .select2-selection {
    ul.select2-selection__rendered {
      padding-right: 0 !important;
    }
  }
}

.select2-selection.select2-selection--multiple {
  padding-top: 4px !important;
  padding-bottom: 4px !important;
  padding-left: 6px !important;

  .select2-selection__rendered {
    min-height: 24px !important;

    .select2-search__field {
      min-height: 24px !important;
    }

    .select2-selection__choice {
      min-height: 24px !important;
    }
  }
}

.form-group.has-error {
  .select2-selection {
      border-color: @brand-danger !important;
  }
}
