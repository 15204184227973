@import (reference) "/assets/css/variables";
@import (reference) "/assets/css/stacks";

.btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 8px;

  &:focus {
    outline: @brand-primary solid 1px;
    outline-offset: -2px;
  }

  &.kv-hidden {
    display: none;
  }
}

.btn-primary {
  .button-variant(@text-light, @brand-primary, darken(@brand-primary, 5%));

  &:focus {
    outline-color: white;
  }
}

.btn-default {
  .button-variant(@text-color, @brand-gray, darken(@brand-gray, 2%));

  .badge {
    background-color: @brand-warning;
  }
}

.btn-default-clear {
  .button-variant(@text-color, white, @border-gray);
}

.btn-link {
  .button-variant(@text-color, transparent, transparent);

  &:hover, &:focus {
    text-decoration: none;
  }
}

.btn-warning {
  .button-variant(@text-light, @brand-warning, darken(@brand-warning, 5%));
}

.btn-danger {
  .button-variant(@text-light, @brand-danger, darken(@brand-danger, 5%));
}

.btn-danger-clear {
  .button-variant(@brand-danger, white, darken(@brand-danger, 5%));
}

.btn-success {
  .button-variant(@text-light, @brand-success, darken(@brand-success, 5%));
}

.btn-xxs {
  .button-size(2px, 4px, 10px, 1, @border-radius-small);

  i {
    font-size: 10px;
  }
}

.btn-xs {
  .button-size(2px, 4px, 10px, 1, @border-radius-small);
  min-height: 20px;

  i {
    font-size: 12px;
  }
}

.btn-sm {
  .button-size(@padding-small-vertical, 6px, 12px, 1, @border-radius-small);
  min-height: @btn-sm-height-base;

  i {
    font-size: 14px;
  }
}

.btn-lg {
  .button-size(8px, 10px, 14px, 20px, @border-radius-small);
}

.btn-header-nav {
  .button-variant(@text-color, @body-bg, darken(@body-bg, 5%));

  min-width: 40px;
  height: 50px;
  border: none;
  border-radius: 0;
  font-weight: 400;
}
