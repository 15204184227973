@import (reference) "/assets/css/bootstrap/variables";
@import "~admin-lte/build/less/sidebar";

.main-sidebar {
    padding-top: 0;

    .sidebar {
        .transition-sidebar;
        width: 240px;
    }

    @media screen and (min-width: @screen-sm) {
        .logo-wrapper {
            .transition-sidebar;
            width: 225px;
            height: 175px;
            margin-left: 16px;
            margin-bottom: 16px;
            padding: 8px;
            background-color: white;
            box-shadow: 0 8px 10px rgba(17, 17, 17, 0.03);
            border-radius: 0 0 @border-radius-large @border-radius-large;

            .logo {
                height: 100%;

                .logo-lg {
                    .transition-sidebar;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    opacity: 1;
                    height: 100%;

                    img {
                        max-height: 100%;
                        max-width: 100%;
                    }
                }

                .logo-mini {
                    display: none;
                }
            }

            .logo__powered-by {
                display: flex;
                align-items: end;
                justify-content: end;
                gap: 4px;
                margin-bottom: 0;
                font-size: 11px;

                img {
                    position: relative;
                    top: -4px;
                }
            }
        }
    }
}

.customer-support-sidebar {
    display: inline-block;
    width: 100%;
    margin-right: 15px;
    margin-bottom: 4px;
    padding: 8px 8px 8px 20px;

    &:hover {
        background-color: darken(@body-bg, 4%);

        .customer-support-sidebar__icon {
            color: @text-icon-active;
        }
    }

    .customer-support-sidebar__icon {
        font-size: 16px;
        color: @text-icon;
    }

    .customer-support-sidebar__text {
        padding-left: 6px;
        font-size: 14px;
        font-weight: 400;
    }
}

.property-selector-sidebar {
    margin-left: 16px;
    margin-bottom: 16px;
    padding: 8px 12px;
    border-radius: @border-radius-small;
    background-color: @brand-primary;
    color: @text-light;

    .property-selector-sidebar__dropdown-icon {
        display: none;
    }

    .property-selector-sidebar__dropdown-container {
        display: flex;
        gap: 8px;
        align-items: center;

        .selected-property {
            display: flex;
            flex-direction: column;
            width: 100%;
            font-size: 12px;

            .selected-property__type {
                font-weight: 400;
                color: darken(@text-light, 10%);
            }

            .selected-property__name {
                font-weight: 600;
                color: @text-light;
            }
        }
    }

    .dropdown-menu {
        width: 100%;
        margin: 0;
        padding: 12px 10px;
        background-color: @brand-primary;
        border: none;
        border-top: 1px solid darken(@brand-primary, 15%);
        border-radius: 0 0 @border-radius-small @border-radius-small;
        overflow-y: auto;

        li {
            padding: 0;
            border-radius: @border-radius-small;

            a, a:hover {
                padding: 12px 10px;
                background-color: transparent;
                font-size: 12px;
                font-weight: 600;
                color: @text-light;
                white-space: break-spaces;
            }

            &:hover {
                background-color: darken(@brand-primary, 10%);
            }
        }

        li.active {
            background-color: darken(@brand-primary, 10%);

            a:hover {
                background-color: darken(@brand-primary, 10%);
            }
        }
    }

    &.open {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;

        .property-selector-sidebar__dropdown-container {
            i:before {
                content: '\f0d8';
            }
        }
    }
}

.sidebar-menu {
    li {
        a {
            display: flex;
            align-items: start;
            padding: 8px 8px 8px 15px;
            font-size: 15px;
            gap: 12px;

             i {
                 margin-right: 0;
                 padding: 0;
                 text-align: center;
                 color: @text-icon;

                 &:first-child {
                     min-width: 24px;
                     width: 24px;
                     font-size: 16px;
                 }
            }

            span {
                display: flex;
                align-items: start;
                width: 100%;

                > span {
                    flex-grow: 1;
                    display: inline-block;
                    white-space: normal;
                }

                > i {
                    display: flex;
                    align-items: center;
                    font-size: 16px;
                    color: @text-icon-active;

                    &.fa-angle-down {
                        position: static;
                        margin: 0 4px 0 0;
                    }

                    &.label {
                        margin-right: 0;
                        padding: @padding-small-vertical 6px;
                        font-style: normal;
                        font-size: 12px;
                    }

                    &.label + i.label {
                        margin-left: 4px;
                    }
                }
            }

            &:hover {
                i {
                    color: @text-icon-active;
                }
            }
        }

        &.active {
            background-color: darken(@body-bg, 6%);

            > a {
                > i {
                    color: @text-icon-active;
                }

                > span > i {
                    color: @text-icon-active;

                    &.fa-angle-down::before {
                        content: '\f106';
                    }
                }
            }
        }
    }

    &:hover > a > span {
        padding-top: 8px;
        padding-bottom: 8px;
    }

    .treeview-menu {
        padding-left: 0;

        > li {
            > a {
                display: flex;
                padding: 6px 8px 6px 25px;
                font-weight: 400;

                i {
                    color: @text-icon;
                }
            }
    
            &:hover {
                background-color: darken(@body-bg, 7%);

                i {
                    color: @text-icon-active;
                }
            }

            &.active {
                background-color: darken(@body-bg, 11%);
            }
          }

        .treeview-menu {
            padding-left: 0;
            background-color: darken(@body-bg, 9%);

            > li {
                > a {
                    padding-left: 45px;
                }

                > a:before {
                    content: '\f068';
                    font-family: "Font Awesome 6 Pro";
                    font-size: 12px;
                    line-height: 20px;
                }

                &:hover {
                    background-color: darken(@body-bg, 12%);
                }

                &.active, &.active:hover {
                    background-color: darken(@body-bg, 14%);
                }
            }
        }
    }

    > li {
        .label,
        .badge {
            margin-top: 0;
        }

        &.divider {
            .nav-divider(@brand-dark-gray);
            opacity: 0.3;
            margin-left: 15px;
            margin-right: 5px;
        }
    }

    .dashboard-divider {
      margin-top: 12px;
      margin-bottom: 12px;
    }
}

.treeview-menu {
  > li.divider {
    .nav-divider(@sidebar-dark-submenu-color);
    opacity: 0.3;

    // To align with `padding-left:5px` on .treeview-menu and `padding: 5px 5px 5px 15px;` on `li > a`
    margin-right: 15px;
    margin-left: 10px;
  }
}

// Style adjustments for sidebar collapse state
.sidebar-collapse {
    @media screen and (min-width: 768px) {
        .sidebar {
            width: 50px;
        }

        .logo-wrapper {
            height: 50px;
            width: 50px;
            margin: 0 0 16px 0;
            background-color: transparent;
            border-radius: 0;
            box-shadow: none;

            .logo {
                .logo-lg {
                    position: absolute;
                    opacity: 0;
                    transition: none;

                    img {
                        width: 0;
                    }
                }

                .logo-mini {
                    display: block;
                    text-align: center;
                }
            }

            .logo__powered-by {
                display: none !important;
            }
        }

        .customer-support-sidebar {
            margin-left: 12px;
            width: auto;
            padding: 4px;

            .customer-support-sidebar__icon {
                display: block;
                padding: 8px;
                font-size: 20px;
                text-align: center;
            }

            .customer-support-sidebar__text {
                display: none;
            }

            &:hover {
                position: relative;

                .customer-support-sidebar__icon {
                    color: @text-icon-active;
                }

                .customer-support-sidebar__text {
                    left: 35px;
                    .sidebar-collapse-item-description;
                }
            }
        }

        .property-selector-sidebar {
            margin-left: 11px;
            margin-right: 4px;
            padding: 0;

            .property-selector-sidebar__dropdown-icon {
                display: block;
                padding-top: 4px;
                padding-bottom: 4px;
                text-align: center;

                .property-selector-sidebar__icon {
                    font-size: 20px;
                }

                .property-selector-sidebar__icon-description {
                    display: none;
                }

                &:hover {
                    .property-selector-sidebar__icon-description {
                        .sidebar-collapse-item-description;
                        top: 0;
                        left: 35px;
                        text-align: start;
                        color: @text-color;
                    }
                }
            }

            .property-selector-sidebar__dropdown-container {
                display: none;
            }
        }

        &.sidebar-mini .sidebar-menu > li:hover > a > span.menu-link-text:not(.pull-right) {
            display: flex !important;
        }

        .sidebar-menu > li {
            > a {
                padding-left: 12px;

                > span {
                    padding: 8px 8px 8px 12px;
                }

                span > i:not(.label) {
                    display: none;
                }
            }

            &:hover {
                > a:first-child {
                    i {
                        color: @text-color;
                        font-weight: 600;
                    }
                }

                > ul.treeview-menu {
                    top: 36px;

                    li {
                        a {
                            padding-left: 16px;
                        }

                        .treeview-menu {
                            li {
                                a {
                                    padding-left: 16px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

// Common styles
.sidebar-collapse-item-description {
    position: absolute;
    top: 0;
    display: block;
    width: 180px;
    padding: 8px 5px 8px 20px;
    background-color: @body-bg;
    border-radius: 0 @border-radius-small @border-radius-small 0;
    font-size: 15px;
    font-weight: 600;
}

.transition-sidebar {
    transition: transform 0.3s linear, width 0.3s ease-in-out;
}

// Dark Sidebar Styles Override
.dark-theme {
    body:not(.sidebar-collapse) .main-sidebar {
        @media screen and (min-width: @screen-sm) {
            .logo-wrapper {
                margin: 0 8px 16px 8px;
            }
        }
    }

    .customer-support-sidebar {
        &:hover {
            padding-left: 17px;
            border-left: 3px solid @brand-secondary;
            background-color: darken(@brand-primary, 4%);
        }

        .customer-support-sidebar__icon {
            color: @text-light;
        }
    }

    .property-selector-sidebar {
        margin: 0 8px 16px 8px;
        background-color: @brand-light;
        color: @brand-primary;

        .property-selector-sidebar__dropdown-container {
            .selected-property {
                .selected-property__type {
                    color: @brand-primary;
                }

                .selected-property__name {
                    color: @brand-primary;
                }
            }
        }

        .dropdown-menu {
            background-color: @brand-light;
            border-top-color: darken(@body-bg, 3%);

            li {
                a, a:hover {
                    color: @brand-primary;
                }

                &:hover {
                    background-color: darken(@body-bg, 3%);
                }
            }

            li.active {
                color: @brand-primary;
                background-color: darken(@body-bg, 3%);

                a:hover {
                    color: @brand-primary;
                    background-color: darken(@body-bg, 3%);
                }
            }
        }
    }

    .sidebar-menu {
        li {
            a {
                i {
                    color: @body-bg
                }

                span {
                    > i {
                        color: darken(@body-bg, 2%);
                    }
                }

                &:hover {
                    i {
                        color: darken(@body-bg, 2%);
                    }
                }
            }

            &.active {
                background-color: darken(@brand-primary, 3%);

                > a {
                    > i {
                        color: darken(@body-bg, 2%);
                    }

                    > span > i {
                        color: darken(@body-bg, 2%);
                    }
                }
            }
        }

        .treeview-menu {
            > li {
                &:hover {
                    background-color: lighten(@brand-primary, 4%);

                    > a {
                        border-left: 3px solid @brand-secondary;
                        padding-left: 22px;
                    }
                }

                &.active {
                    background-color: lighten(@brand-primary, 4%);

                    > a {
                        border-left: 3px solid @brand-secondary;
                        padding-left: 22px;
                    }
                }
            }

            .treeview-menu {
                background-color: lighten(@brand-primary, 16%);

                > li {
                    &:hover {
                        background-color: lighten(@brand-primary, 12%);

                        > a {
                            border-left: 3px solid @brand-secondary;
                            padding-left: 42px;
                        }
                    }

                    &.active, &.active:hover {
                        background-color: lighten(@brand-primary, 12%);

                        > a {
                            border-left: 3px solid @brand-secondary;
                            padding-left: 42px;
                        }
                    }
                }
            }
        }

        > li {
            &.divider {
                .nav-divider(@body-bg);
            }
        }
    }

    .treeview-menu {
        > li.divider {
            .nav-divider(@body-bg);
        }
    }

    .sidebar-collapse {
        @media screen and (min-width: 768px) {
            .customer-support-sidebar {
                margin-left: 0;
                margin-right: 0;

                &:hover {
                    padding: 4px 0 4px 1px;

                    > i {
                        color: @text-light;
                    }

                    > .customer-support-sidebar__text {
                        left: 47px;
                        background: darken(@brand-primary, 4%);
                    }
                }
            }

            .property-selector-sidebar {
                .property-selector-sidebar__dropdown-icon {
                    &:hover {
                        .property-selector-sidebar__icon-description {
                            background-color: @brand-primary;
                            color: @text-light;
                        }
                    }
                }
            }

            .sidebar-menu > li {
                &:hover {
                    > a:first-child {
                        i {
                            color: @text-light;
                        }
                    }

                    > ul.treeview-menu {
                        > li:hover,
                        > li.active {
                            > a {
                                padding-left: 12px;
                            }

                            .treeview-menu li {
                                a {
                                    border-left: none;
                                    padding-left: 16px;
                                }
                            }
                        }
                    }
                }

                > a {
                    padding-left: 9px;
                }
            }
        }
    }
}
