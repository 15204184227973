@import (reference) "/assets/css/buttons";

.chip-wrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: start;
  gap: 4px;
}

.chip {
  .btn;
  .btn-default;
  .btn-sm;

  border-radius: @border-radius-base;
  border: 1px solid transparent;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;

  &.disabled {
    cursor: default;
  }

  .chip-remove {
    font-size: 16px;
    opacity: 0.5;

    &:hover {
      cursor: pointer;
      opacity: 1;
    }
  }
}
